import { createAction } from '../utility/redux-utility';
import { authFetch } from '../auth';
import { WORKSPACES_API_URL_ROOT } from '../config';
import { replace } from "redux-first-history";
import { mapDropdownToUrl } from '../mapper/analysisDynamicWorkspaceMapper';
import { getFullPath } from '../utility/workspace-utility';
import { dashboardTileEditAddNew } from './dashboard-tiles';

export const dashboardTilesDynamicWorkspaceSelectWorkspace = (workspace) => async (dispatch, getState) => {
  dispatch(dashboardTilesDynamicWorkspaceSelectWorkspaceBegin());
  return await authFetch(`${WORKSPACES_API_URL_ROOT}/v3/workspaces?dataVersion=3&path=${encodeURIComponent(workspace.path)}`)
    .then(response => response.json())
    .then(workspace => {
      const workspaceData = JSON.parse(workspace.data);
      dispatch(dashboardTilesDynamicWorkspaceSelectWorkspaceComplete(workspace, workspaceData));
    })
    .catch(() => {
      dispatch(dashboardTilesDynamicWorkspaceSelectWorkspaceComplete());
    });
};

export const DASHBOARD_TILES_DYNAMIC_WORKSPACE_SELECT_WORKSPACE_BEGIN = 'DASHBOARD_TILES_DYNAMIC_WORKSPACE_SELECT_WORKSPACE_BEGIN';
const dashboardTilesDynamicWorkspaceSelectWorkspaceBegin = createAction(DASHBOARD_TILES_DYNAMIC_WORKSPACE_SELECT_WORKSPACE_BEGIN);

export const DASHBOARD_TILES_DYNAMIC_WORKSPACE_SELECT_WORKSPACE_COMPLETE = 'DASHBOARD_TILES_DYNAMIC_WORKSPACE_SELECT_WORKSPACE_COMPLETE';
const dashboardTilesDynamicWorkspaceSelectWorkspaceComplete = createAction(DASHBOARD_TILES_DYNAMIC_WORKSPACE_SELECT_WORKSPACE_COMPLETE, 'workspace', 'workspaceData');


export const DASHBOARD_TILES_DYNAMIC_WORKSPACE_SET_MULTISELECT_TO_CREATE_SINGLE_TILE = 'DASHBOARD_TILES_DYNAMIC_WORKSPACE_SET_MULTISELECT_TO_CREATE_SINGLE_TILE';
export const dashboardTilesDynamicWorkspaceSetMultiSelectToCreateSingleTile = createAction(DASHBOARD_TILES_DYNAMIC_WORKSPACE_SET_MULTISELECT_TO_CREATE_SINGLE_TILE, 'dynamicWorkspaceKey', 'dynamicFilterKey', 'value');

export const dashboardTilesDynamicWorkspaceAddToDashboard = () => async (dispatch, getState) => {
  const state = getState();
  const combinations = state.getIn(['dashboardTiles', 'dynamicWorkspaceDesign', 'combinations'])?.toJS();
  if (combinations.length === 0)
    return state;

  const title = state.getIn(['dashboardTiles', 'dynamicWorkspaceDesign', 'title']);
  const dynamicWorkspaceToolbarStyle = state.getIn(['dashboardTiles', 'dynamicWorkspaceDesign', 'dynamicWorkspaceToolbarStyle']) ?? 'default';
  const workspace = state.getIn(['dashboardTiles', 'workspacePicker', 'selectedWorkspace']);
  const workspacePath = getFullPath({ workspace, includeType: false });

  combinations.forEach(options => {
    const overrides = [];

    options.forEach(o => {
      let override = overrides.find(e => e.key === o.key);
      if (!override){
        override = {
          key: o.key,
          filters: []
        };

        overrides.push(override);
      }

      if (o.isMultiSelect & o.groupMultiselectOptions) {
        override.filters = [...override.filters, {
          isMultiSelect: o.isMultiSelect,
          dynamicFilterKey: o.dynamicFilterKey,
          value: undefined,
          values: o.values
        }];
      } else {
        override.filters = [...override.filters, ...o.values.map(v => ({
          isMultiSelect: o.isMultiSelect,
          dynamicFilterKey: o.dynamicFilterKey,
          value: o.isMultiSelect !== true ? v : undefined,
          values: o.isMultiSelect === true ? [v] : undefined,
        }))];
      }
    })

    dispatch(dashboardTileEditAddNew(
      'AnalysisChart',
      {
        title: title,
        workspacePath: workspacePath,
        dynamicWorkspaceToolbarStyle: dynamicWorkspaceToolbarStyle,
        overrideDynamicWorkspace: overrides,
        useDashboardDates: false,
        useDashboardLens: false,
        useDashboardTimezone: false,
      }));
  });
};

export const DASHBOARD_TILES_DYNAMIC_WORKSPACE_INITIALISE = 'DASHBOARD_TILES_DYNAMIC_WORKSPACE_INITIALISE';
export const dashboardTilesDynamicWorkspaceInitialise = createAction(DASHBOARD_TILES_DYNAMIC_WORKSPACE_INITIALISE);

export const DASHBOARD_TILES_DYNAMIC_WORKSPACE_GO_NEXT = 'DASHBOARD_TILES_DYNAMIC_WORKSPACE_GO_NEXT';
export const dashboardTilesDynamicWorkspaceGoNext = createAction(DASHBOARD_TILES_DYNAMIC_WORKSPACE_GO_NEXT);

export const DASHBOARD_TILES_DYNAMIC_WORKSPACE_GO_BACK = 'DASHBOARD_TILES_DYNAMIC_WORKSPACE_GO_BACK';
export const dashboardTilesDynamicWorkspaceGoBack = createAction(DASHBOARD_TILES_DYNAMIC_WORKSPACE_GO_BACK);

export const DASHBOARD_TILES_DYNAMIC_WORKSPACE_DESIGN_SET_VALUE = 'DASHBOARD_TILES_DYNAMIC_WORKSPACE_DESIGN_SET_VALUE';
export const dashboardTilesDynamicWorkspaceDesignSetValue = createAction(DASHBOARD_TILES_DYNAMIC_WORKSPACE_DESIGN_SET_VALUE, 'key', 'value');

export const DASHBOARD_TILES_DYNAMIC_WORKSPACE_SET_OPTION = 'DASHBOARD_TILES_DYNAMIC_WORKSPACE_SET_OPTION';
export const dashboardTilesDynamicWorkspaceSetOption = createAction(DASHBOARD_TILES_DYNAMIC_WORKSPACE_SET_OPTION, 'dynamicWorkspaceKey', 'dynamicFilterKey', 'name', 'isSelected');

export const DASHBOARD_TILES_DYNAMIC_WORKSPACE_ALL_TOGGLE_OPTIONS = 'DASHBOARD_TILES_DYNAMIC_WORKSPACE_ALL_TOGGLE_OPTIONS';
export const dashboardTilesDynamicWorkspaceToggleAllOptions = createAction(DASHBOARD_TILES_DYNAMIC_WORKSPACE_ALL_TOGGLE_OPTIONS, 'dynamicWorkspaceKey', 'dynamicFilterKey');

export const DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_INITIALISE = 'DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_INITIALISE';
export const dashboardTilesDynamicWorkspaceDropdownsEditorInitialise = createAction(DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_INITIALISE);

export const DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SAVE_CHANGES = 'DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SAVE_CHANGES';
export const dashboardTilesDynamicWorkspaceDropdownsEditorSaveChanges = createAction(DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SAVE_CHANGES);

export const DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SET_FILTER_SHOW_IN_TILE = 'DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SET_FILTER_SHOW_IN_TILE';
export const dashboardTilesDynamicWorkspaceDropdownsEditorSetFilterShowInTile = createAction(DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SET_FILTER_SHOW_IN_TILE, 'filterName', 'value');

export const DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SET_FILTER_TILE = 'DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SET_FILTER_TILE';
export const dashboardTilesDynamicWorkspaceDropdownsEditorSetFilterTile = createAction(DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_SET_FILTER_TILE, 'filterName', 'tileKey', 'value');

export const DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_TOGGLE_FILTER_TILES = 'DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_TOGGLE_FILTER_TILES';
export const dashboardTilesDynamicWorkspaceDropdownsEditorToggleFilterTiles = createAction(DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_EDITOR_TOGGLE_FILTER_TILES, 'filterName');

export const DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_REBUILD_OPTIONS = 'DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_REBUILD_OPTIONS';
export const dashboardTilesDynamicWorkspaceDropdownsRebuildOptions = createAction(DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_REBUILD_OPTIONS);

export const dashboardTilesDynamicWorkspaceDropdownsSelectOption = (filterName, value, multiSelectValues) => async (dispatch, getState) => {
  dispatch(replace(mapDropdownToUrl(filterName, value, multiSelectValues)));
  dispatch(dashboardTilesDynamicWorkspaceDropdownsSelectOptionComplete(filterName, value, multiSelectValues));
};

export const DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_SELECT_OPTION_COMPLETE = 'DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_SELECT_OPTION_COMPLETE';
const dashboardTilesDynamicWorkspaceDropdownsSelectOptionComplete = createAction(DASHBOARD_DYNAMIC_WORKSPACE_DROPDOWNS_SELECT_OPTION_COMPLETE, 'filterName', 'value', 'multiSelectValues');