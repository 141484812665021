import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Fade from '@mui/material/Fade';

export function PopoverButton({ id, onOpenPopover, onClosePopover, children, buttonContent, title, buttonClassName='btn btn-sm', buttonStyles={}, disabled = false, data_id='popover' }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function openPopover(e) {
    if (onOpenPopover) 
      onOpenPopover();
    setAnchorEl(e.currentTarget);
  }

  function closePopover(){
    setAnchorEl(null);
  }

  function popoverClosed() {
    if (onClosePopover)
      onClosePopover();
    setAnchorEl(null);
  }

  function onInnerClick(e) {
    if (e) {
      if (e.target.className === 'dropdown-item' || 
          e.target.getAttribute('data-dismiss') ||
          e.target.getAttribute('data-close-popover')){
        closePopover();
      }
    }
  }

  return <>
      <button data_id={`${data_id}-open`} id={id} disabled={disabled} type='button' onClick={openPopover} title={title} className={buttonClassName} style={buttonStyles}>
        {buttonContent}
      </button>
      <Popover anchorEl={anchorEl} open={!!anchorEl} onClose={popoverClosed}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        TransitionComponent={Fade}
        >
        <div onClick={e => onInnerClick(e)}>
          {anchorEl ? <>{typeof children === 'function' ? children({ closePopover }) : children}</> : <></>}
        </div>
      </Popover>
    </>
}