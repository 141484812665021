import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, matchPath } from 'react-router';
import qs from 'querystring';
import { toJS } from '../../../utility/immutable-utility';
import AnalysisPageHeader from './AnalysisPageHeader';
import AnalysisChart from './AnalysisChart';
import AnalysisChartDataConnected from './AnalysisChartDataConnected';
import AnalysisSplitChart from './AnalysisSplitChart';
import { SearchConnected } from '../analysis-cross-schema-search/SearchConnected';
import AnalysisSideBar from './AnalysisSideBar';
import Loading from '../../shared/Loading';
import ErrorBoundary from '../../shared/ErrorBoundary';
import AnalysisHighChartSettings from './AnalysisHighChartSettings';
import AnalysisChartSettings from './AnalysisChartSettings';
import { BasketConnectComponent } from '../analysis-baskets/BasketConnectComponent';
import { isScreenCapture } from '../../shared/HideOnScreenCapture';
import {
  analysisRefresh,
  analysisUIToggleCollapsibleSideBar,
  analysisUITogglePanelVisibility,
  analysisUIResizePanels,
  analysisUIUpdateReflowSwitch,
  analysisWorkspacePathLoad,
  analysisWorkspacePathNew,
  analysisHideOverlay
} from '../../../actions/analysis';
import {
  analysisToggleSeriesVisibiliy2,
  analysisToggleChartSeriesVisibility
} from '../../../actions/analysis-basket-v2';
import { useLocation } from 'react-router-dom';
import { Allotment } from "allotment";
import { Drawer } from '@mui/material';
import { referenceDataLoadConversionUnits, referenceDataLoadPeriods } from '../../../actions/referenceData';
import { analysisCrossSchemaSearchLoadSchemaLookups } from '../../../actions/analysis-cross-schema-search';
import { RegisterDashboardItem } from '../../../mapper/dashboardTileMapper';

const AnalysisCharts = connect(
  (state) => ({
    isLoading: state.getIn(['analysis', 'chart', 'isLoading']),
    refreshRequired: state.getIn(['analysis', 'chart', 'refreshRequired']),
    comparisonMode: state.getIn(['analysis', 'chart', 'comparisonMode']),
    lens: state.getIn(['analysis', 'chart', 'lens']),
    chartSettings: state.getIn(['analysis', 'workspace', 'chartSettings']),
    isEvolution: state.getIn(['analysis', 'workspace', 'isEvolution']),
    chartSeries: state.getIn(['analysis', 'chart', 'series']),
    chartCategories: state.getIn(['analysis', 'chart', 'chartCategories']),
    hasXAxis: state.getIn(['analysis', 'chart', 'hasXAxis']),
    chartStartDate: state.getIn(['analysis', 'chart', 'startDate']),
    chartEndDate: state.getIn(['analysis', 'chart', 'endDate'])
  }),
  (dispatch) => ({
    refresh() {
      dispatch(analysisRefresh());
    },
    toggleChartSeriesVisibility(key) {
      dispatch(analysisToggleChartSeriesVisibility(key));
    }
  })
  )(({ chartSeries, chartCategories, hasXAxis, reflowSwitch, isLoading, refreshRequired, comparisonMode, lens,
    refresh, chartSettings, toggleChartSeriesVisibility, chartStartDate, chartEndDate, 
    showDynamicWorkspaceOptions, isWorkspaceDetailsVisible, pageHeaderHeight }) => {
    const _chartSeries = toJS(chartSeries);
  
    useEffect(() => {
      if (refreshRequired) {
        refresh();
    }
  }, [refreshRequired, refresh]);

  return (
    <ErrorBoundary>
      <Loading isLoading={isLoading}>
        <>
          <div style={{ height: pageHeaderHeight }}>
            <AnalysisPageHeader showDynamicWorkspaceOptions={showDynamicWorkspaceOptions} isWorkspaceDetailsVisible={isWorkspaceDetailsVisible} />
          </div>
          <div style={{ height: pageHeaderHeight ? `calc(100% - ${pageHeaderHeight})` : '100%' }}>
            {_chartSeries && _chartSeries.length > 0 && <AnalysisChart
                chartSeries={_chartSeries}
                chartCategories={chartCategories}
                comparisonMode={comparisonMode}
                lens={lens}
                toggleChartSeriesVisibility={toggleChartSeriesVisibility}
                reflowSwitch={reflowSwitch}
                chartSettings={chartSettings}
                hasXAxis={hasXAxis}
                chartStartDate={chartStartDate}
                chartEndDate={chartEndDate} />}
          </div>
        </>
      </Loading>
    </ErrorBoundary>
  );
});

const AnalysisSplitCharts = connect(
  (state) => ({
    isLoading: state.getIn(['analysis', 'chart', 'isLoading']),
    refreshRequired: state.getIn(['analysis', 'chart', 'refreshRequired']),
    comparisonMode: state.getIn(['analysis', 'chart', 'comparisonMode']),
    lens: state.getIn(['analysis', 'chart', 'lens']),
    chartSettings: state.getIn(['analysis', 'workspace', 'chartSettings']),
    isEvolution: state.getIn(['analysis', 'workspace', 'isEvolution']),
    chartSeries: state.getIn(['analysis', 'chart', 'series']),
    chartCategories: state.getIn(['analysis', 'chart', 'chartCategories']),
    hasXAxis: state.getIn(['analysis', 'chart', 'hasXAxis']),

    chartStartDate: state.getIn(['analysis', 'chart', 'startDate']),
    chartEndDate: state.getIn(['analysis', 'chart', 'endDate'])
  }),
  (dispatch) => ({
    refresh() {
      dispatch(analysisRefresh());
    },
    toggleChartSeriesVisibility(key) {
      dispatch(analysisToggleChartSeriesVisibility(key));
    }
  })
)(({ chartSeries, chartCategories, hasXAxis, reflowSwitch, isLoading, refreshRequired, comparisonMode, lens,
  refresh, chartSettings, isEvolution, toggleChartSeriesVisibility, chartStartDate, chartEndDate, 
  showDynamicWorkspaceOptions, isWorkspaceDetailsVisible, pageHeaderHeight }) => { 
  const _chartSeries = toJS(chartSeries);

  useEffect(() => {
    if (refreshRequired) {
        refresh();
    }
  }, [refreshRequired, refresh]);

  return (
    <ErrorBoundary>
      <Loading isLoading={isLoading}>
        <div className='w-100 h-100'>
          <div style={{ height: pageHeaderHeight }}>
            <AnalysisPageHeader showDynamicWorkspaceOptions={showDynamicWorkspaceOptions} isWorkspaceDetailsVisible={isWorkspaceDetailsVisible} />
          </div>
          <div style={{ height: pageHeaderHeight ? `calc(100% - ${pageHeaderHeight})` : '100%', overflowY: 'auto' }}>
            <AnalysisSplitChart
              chartSeries={_chartSeries}
              chartCategories={chartCategories}
              comparisonMode={comparisonMode}
              lens={lens}
              toggleChartSeriesVisibility={toggleChartSeriesVisibility}
              reflowSwitch={reflowSwitch}
              chartSettings={chartSettings}
              hasXAxis={hasXAxis}
              chartStartDate={chartStartDate}
              chartEndDate={chartEndDate} />
          </div>
        </div>
      </Loading>
    </ErrorBoundary>
  );
});

const AnalysisChartsData = connect(
  (state) => ({
    isLoading: state.getIn(['analysis', 'chart', 'isLoading']),
    refreshRequired: state.getIn(['analysis', 'chart', 'refreshRequired']),
    isEvolution: state.getIn(['analysis', 'workspace', 'isEvolution'])
  }),
  (dispatch) => ({
    refresh() {
      dispatch(analysisRefresh());
    },
    toggleVisibility(key) {
      dispatch(analysisToggleSeriesVisibiliy2(key));
    }
  })
)(({ reflowSwitch, isLoading, refreshRequired, refresh, isEvolution, 
  showDynamicWorkspaceOptions, isWorkspaceDetailsVisible }) => {
  
  useEffect(() => {
    if (refreshRequired) {
        refresh();
    }
  }, [refreshRequired, isEvolution, refresh]);

  return (
    <ErrorBoundary>
      <Loading isLoading={isLoading}>
        <div className='d-flex flex-column h-100'>
          <AnalysisPageHeader showDynamicWorkspaceOptions={showDynamicWorkspaceOptions} isWorkspaceDetailsVisible={isWorkspaceDetailsVisible} />
          <div className='flex-fill'>
            <AnalysisChartDataConnected
              reflowSwitch={reflowSwitch}
              isLoading={isLoading} />
          </div>
        </div>
      </Loading>
    </ErrorBoundary>
  );
});

const AnalysisWorkspaceLoader = connect(
  (state) => ({
    workspacePath: state.getIn(['analysis', 'workspacePath'])
  }),
  (dispatch) => ({
    loadWorkspace(path, dynamicFilter) {
      dispatch(analysisWorkspacePathLoad(path, '/analysis/chart', dynamicFilter));
    },
    newWorkspace(_workspaceParams) {
      dispatch(analysisWorkspacePathNew(_workspaceParams, '/analysis/chart'));
    }
  })
)(({ children, workspacePath, loadWorkspace, newWorkspace }) => {
  const location = useLocation();
  const _workspaceParams = useMemo(() => {
    let criteria = qs.parse(location.search.startsWith('?') ? location.search.substring(1) : location.search);
    if(!criteria.key || criteria.key.length === 0) return undefined;

    criteria.key = Array.isArray(criteria.key) ? criteria.key : [criteria.key]; // makes key an array if it isnt, ie. 1 item passed
    return criteria;
  }, [location.search]);

  useEffect(() => {
    if (workspacePath){
      var url = new URLSearchParams(workspacePath);
      const path = url.get('workspacePath');
      const dynamicFilter = JSON.parse(url.get('dynamicFilter') ?? '[]');
      loadWorkspace(path, dynamicFilter);
    }
  }, [loadWorkspace, workspacePath]);

  useEffect(() => {
    if (_workspaceParams) newWorkspace(_workspaceParams);
  }, [newWorkspace, _workspaceParams]);

  return children;
});

const PanelIndexes = {
    ReportSettings : 0,
    ChartSettings : 1,
    BasketContainer: 1
};

function isMatchPathMany(location, paths){
  for (let index = 0; index < paths.length; index++) {
    const path = paths[index];
    if (matchPath({path:path}, location.pathname)){
      return true;
    }
  }

  return false;
}

const Analysis = connect(
  (state) => ({
    panelWidths: state.getIn(['analysis', 'ui', 'panelWidths']),
    reflowSwitch: state.getIn(['analysis', 'ui', 'reflowSwitch']),
    isSideBarCollapsed: state.getIn(['analysis', 'ui', 'isSideBarCollapsed']),
    overlayPanelName: state.getIn(['analysis', 'overlay', 'panelName']),
    seriesSettingsPanelIsHidden: state.getIn(['analysis', 'ui', 'panelWidths', 'seriesSettings', 'isHidden']),
    workspaceId: state.getIn(['analysis', 'workspace', 'id']),
    workspaceName: state.getIn(['analysis', 'workspace', 'name'])    
  }),
  (dispatch) => ({
    toggleCollapsibleSideBar() {
      dispatch(analysisUIToggleCollapsibleSideBar());
    },
    toggleHighChartSettingsPin() {
      dispatch(analysisUITogglePanelVisibility('chartSettings'));
    },
    toggleChartSettingsPin() {
      dispatch(analysisUITogglePanelVisibility('reportSettings'));
    },
    toggleSeriesSettingsPin() {
      dispatch(analysisUITogglePanelVisibility('seriesSettings'));
    },
    resizePanels(keys, panels) {
      dispatch(analysisUIResizePanels(keys, panels));
    },
    updateReflowSwitch() {
      dispatch(analysisUIUpdateReflowSwitch());
    },    
    hideOverlay() {
      dispatch(analysisHideOverlay());
    },
    initialise() {
      dispatch(analysisCrossSchemaSearchLoadSchemaLookups());
      dispatch(referenceDataLoadConversionUnits());
      dispatch(referenceDataLoadPeriods());      
    }
  })
)(({ reflowSwitch, updateReflowSwitch, initialise,
  overlayPanelName, seriesSettingsPanelIsHidden, 
  workspaceId, workspaceName,
  hideOverlay, panelWidths, resizePanels, isSideBarCollapsed, toggleCollapsibleSideBar, toggleHighChartSettingsPin, toggleChartSettingsPin, toggleSeriesSettingsPin }) => {

  useEffect(() => {
    initialise();
  }, [initialise]);

  useLayoutEffect(() => {
    let resizeId;
    const onResize = () => {
      clearTimeout(resizeId);
      resizeId = setTimeout(() => updateReflowSwitch(), 500);
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [updateReflowSwitch]);
  
  const _showDynamicWorkspaceOptions = (overlayPanelName === 'SeriesSettingsPanel' ^ seriesSettingsPanelIsHidden === true) === 1;
  const _isWorkspaceDetailsVisible = !!workspaceId && !!workspaceName;
  let _pageHeaderHeight = '';
  if (_showDynamicWorkspaceOptions && _isWorkspaceDetailsVisible) _pageHeaderHeight = '70px'; 
  else if (_showDynamicWorkspaceOptions) _pageHeaderHeight = '33px'; 
  else if (_isWorkspaceDetailsVisible) _pageHeaderHeight = '37px'; 

  const _panelWidths = toJS(panelWidths) ?? {};
  const _isScreenCapture = isScreenCapture();
  const contentPanelMaxDimensions = {
    width:'100%',
    height:'100%',
    maxWidth : _isScreenCapture ? '2000px' : undefined,
    maxHeight : _isScreenCapture ? '1000px' : undefined
  };

  const location = useLocation();
  const showSideNav = !(window.__APIKEY__ !== undefined && window.__IS_SCREEN_CAPTURE_ALLOWED);
  const showReportCriteria = (!_panelWidths.reportSettings.isHidden) && isMatchPathMany(location, ['/analysis/chart/*', '/analysis/split-chart/*', '/analysis/chart-data/*' ]);
  const showHighChartSettings = (!_panelWidths.chartSettings.isHidden) && isMatchPathMany(location, ['/analysis/chart/*','/analysis/split-chart/*']);
  const showSelectionBasket = (!_panelWidths.seriesSettings.isHidden);

  function onToggleCollapsibleSideBar(){
    resizePanels(['sideBarPanel'], [{ size: !isSideBarCollapsed ? 56 : 217}]);
    toggleCollapsibleSideBar();
  }

  function onPanelsChange(source, panels){
    const keys = [];
    const panelWidths = [];

    if (source === 'seriesSettingsContainer'){
      keys.push('seriesSettings');
      panelWidths.push({size: panels[PanelIndexes.BasketContainer]});
    } else {
      if (!_panelWidths.reportSettings.isHidden){
        keys.push('reportSettings');
        panelWidths.push({size: panels[PanelIndexes.ReportSettings]});
      }

      if (!_panelWidths.chartSettings.isHidden){
        keys.push('chartSettings');
        panelWidths.push({size: panels[PanelIndexes.ChartSettings]});
      }
    }

    resizePanels(keys, panelWidths);
  }

  const showReportCriteriaDrawer = !_isScreenCapture && !showReportCriteria && overlayPanelName === 'ReportSettingsPanel';
  const showBasketDrawer = !_isScreenCapture && !showSelectionBasket && overlayPanelName === 'SeriesSettingsPanel';
  const showHighChartSettingsDrawer = !_isScreenCapture && !showHighChartSettings && overlayPanelName === 'HighChartSettings';

  return <>
  <AnalysisWorkspaceLoader>
    <div style={contentPanelMaxDimensions}>
      <Allotment vertical={false} separator={false} >
        <Allotment.Pane visible={!_isScreenCapture && showSideNav} minSize={ _panelWidths['sideBarPanel'].size } maxSize={ _panelWidths['sideBarPanel'].size } preferredSize={_panelWidths['sideBarPanel'].size}>
          <aside className={isSideBarCollapsed ? 'collapsed' : ''}>
            <AnalysisSideBar style={{width: _panelWidths['sideBarPanel'].size}} toggleIsCollapsed={onToggleCollapsibleSideBar} />
          </aside>
        </Allotment.Pane>

        <Allotment.Pane>
          <Allotment vertical={true} onDragEnd={e => onPanelsChange('seriesSettingsContainer', e)}>
            <Allotment.Pane>

              <Allotment vertical={false} proportionalLayout={false} onDragEnd={e => onPanelsChange('', e)}>
                <Allotment.Pane visible={!_isScreenCapture && showReportCriteria} minSize={400} preferredSize={_panelWidths.reportSettings.size}>
                  <Routes>
                    <Route path='/chart/*' element={<AnalysisChartSettings toggleIsPinned={toggleChartSettingsPin} />}></Route>
                    <Route path='/split-chart/*' element={<AnalysisChartSettings toggleIsPinned={toggleChartSettingsPin} />}></Route>
                    <Route path='/chart-data/*' element={<AnalysisChartSettings toggleIsPinned={toggleChartSettingsPin} />}></Route>
                  </Routes>
                </Allotment.Pane>

                <Allotment.Pane visible={!_isScreenCapture && showHighChartSettings} minSize={400} preferredSize={_panelWidths.chartSettings.size}>
                  <Routes>
                    <Route path='/chart/*' element={<AnalysisHighChartSettings toggleIsPinned={toggleHighChartSettingsPin} />}></Route>
                    <Route path='/split-chart/*' element={<AnalysisHighChartSettings toggleIsPinned={toggleHighChartSettingsPin} />}></Route>
                  </Routes>
                </Allotment.Pane>

                <Allotment.Pane>
                  <Routes>
                    <Route path='/chart/*' element={<AnalysisCharts reflowSwitch={reflowSwitch} 
                      showDynamicWorkspaceOptions={_showDynamicWorkspaceOptions} 
                      isWorkspaceDetailsVisible={_isWorkspaceDetailsVisible}
                      pageHeaderHeight={_pageHeaderHeight} />}></Route>
                    <Route path='/split-chart/*' element={<AnalysisSplitCharts reflowSwitch={reflowSwitch} 
                      showDynamicWorkspaceOptions={_showDynamicWorkspaceOptions} 
                      isWorkspaceDetailsVisible={_isWorkspaceDetailsVisible}
                      pageHeaderHeight={_pageHeaderHeight} />}></Route>
                    <Route path='/chart-data/*' element={<AnalysisChartsData reflowSwitch={reflowSwitch} 
                      showDynamicWorkspaceOptions={_showDynamicWorkspaceOptions} 
                      isWorkspaceDetailsVisible={_isWorkspaceDetailsVisible} />} ></Route>
                    <Route path='/*' element={<AnalysisSearch 
                      isWorkspaceDetailsVisible={_isWorkspaceDetailsVisible} />} ></Route>
                  </Routes>
                </Allotment.Pane>
              </Allotment>
              
            </Allotment.Pane>

            <Allotment.Pane visible={!_isScreenCapture && showSelectionBasket} preferredSize={_panelWidths['seriesSettings'].size}>
              {showSelectionBasket && <Routes>
                <Route path='/*' element={<BasketConnectComponent toggleIsPinned={toggleSeriesSettingsPin}/>}></Route>
              </Routes>}
            </Allotment.Pane>
          </Allotment>        
        </Allotment.Pane>
      </Allotment>    
    </div>
  </AnalysisWorkspaceLoader>

  <Drawer variant='temporary' anchor='bottom' onClose={() => hideOverlay()} open={showBasketDrawer}>
    {showBasketDrawer && <div style={{maxHeight:'50vh'}} >
      <BasketConnectComponent toggleIsPinned={toggleSeriesSettingsPin} />
    </div>}
  </Drawer>

  <Drawer variant='temporary' anchor='left' onClose={() => hideOverlay()} open={showReportCriteriaDrawer}>
    {showReportCriteriaDrawer && <div style={{minWidth:'400px', maxWidth:'400px', height:'100%'}} >
      <AnalysisChartSettings toggleIsPinned={toggleChartSettingsPin} />
    </div>}
  </Drawer>

  <Drawer variant='temporary' anchor='left' onClose={() => hideOverlay()} open={showHighChartSettingsDrawer}>
    {showHighChartSettingsDrawer && <div style={{minWidth:'400px', maxWidth:'50vw', height:'100%'}} >
      <AnalysisHighChartSettings toggleIsPinned={ toggleHighChartSettingsPin } />
    </div>}
  </Drawer>
  </>
});

function AnalysisSearch({ isWorkspaceDetailsVisible }) {
  return <div className='d-flex flex-column h-100'>
      <AnalysisPageHeader showDynamicWorkspaceOptions={false} isWorkspaceDetailsVisible={isWorkspaceDetailsVisible} />
      <div className='pt-3 pl-3 pr-3 flex-fill h-100'>
        <SearchConnected stateContext='default' />
      </div>
    </div>
}

export default Analysis;

RegisterDashboardItem({
  type :'AnalysisChart',
  dashboardFilterOptions : ['Lens', 'Dates', 'Timezone'],
  dashboardCriteriaOptions : (workspace, tileConfig) => {
    const dashboardOption = {
      useDashboardLens : tileConfig.useDashboardLens ?? false,
      useDashboardTimezone : tileConfig.useDashboardTimezone ?? false,
      useDashboardDates : false,
      workspacePeriods: []
    }

    if (tileConfig.useDashboardDates) {
      if (!workspace.period) {
        workspace.timeseries.forEach(ts => {
          if (!ts.periods && ts.windowType !== 'override' && (!ts.comparisonMode || ts.comparisonMode === 'none'))
            dashboardOption.useDashboardDates = true;
        });
      }
    }

    if (tileConfig.useDashboardDates) {
      if (Array.isArray(workspace.periods))
        dashboardOption.workspacePeriods = workspace.periods;
    }

    return dashboardOption;
  }
});