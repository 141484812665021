
import React from 'react';
import { connect } from 'react-redux';
import { ModalDialogButton } from '../../shared/ModalDialogButton';
import {
  dashboardTilesDynamicWorkspaceDropdownsEditorInitialise,
  dashboardTilesDynamicWorkspaceDropdownsEditorSaveChanges,
  dashboardTilesDynamicWorkspaceDropdownsEditorSetFilterShowInTile,
  dashboardTilesDynamicWorkspaceDropdownsEditorSetFilterTile,
  dashboardTilesDynamicWorkspaceDropdownsEditorToggleFilterTiles,
  dashboardTilesDynamicWorkspaceDropdownsSelectOption
} from '../../../actions/dashboard-tiles-dynamic-workspace';
import { getToggleAllCheckStyle } from '../../../utility/selection-utility';
import CollapsePanel from '../../shared/CollapsePanel';
import { MultiselectDropdown } from '../../shared/MultiselectDropdown';

export const DynamicWorkspaceDropdownsConnected = connect(
  (state) => ({
    dropdowns: state.getIn(['dashboardTiles', 'dynamicWorkspaceDropdowns'])
  }),
  (dispatch) => ({
    selectFilterOption(filterName, value, multiSelectValues) {
      dispatch(dashboardTilesDynamicWorkspaceDropdownsSelectOption(filterName, value, multiSelectValues));
    }
  })
)(({ dropdowns, selectFilterOption }) => {
  const _dropdowns = dropdowns ? dropdowns.toJS() : [];

  function onSelectFilterOption(filterName, selectedValue) {
    if (selectedValue !== '') {
      selectFilterOption(filterName, selectedValue, undefined);
    }
  }

  function onMultiSelectFilterOption(filterName, multiSelectValues) {
      selectFilterOption(filterName, undefined, multiSelectValues);
  }

  return <div className='d-flex' data_id='dynamic-filter-dropdowns' >
    {_dropdowns.map((dropdown, i) => {
      return <div key={dropdown.filterName} className='d-flex ml-1 dynamic-filter-toolbar-item'>
        <span className='mt-1 ml-1 mr-1 fota-text-black'>{dropdown.filterName}</span>
        {dropdown.isMultiSelect ?
          <MultiselectDropdown
            className='multiselect-sm'
            style={{ minWidth: '200px', maxHeight: '28px', overflowX: 'hidden', overflowY:'auto' }}
            data={dropdown.options.map(o => ({ name: o }))}
            dataKey='name'
            textField='name'
            onChange={values => onMultiSelectFilterOption(dropdown.filterName, values.map(v => v.name))}
            value={dropdown.multiSelectValues ?? []}></MultiselectDropdown>
          :
          <select data_id={`dynamic-filter-dropdown-${i}`} className='form-control form-control-sm' style={{ minWidth: '100px', maxWidth: '150px' }} value={dropdown.selectedValue} onChange={e => onSelectFilterOption(dropdown.filterName, e.target.value)}>
            {dropdown.options.map(o => <option key={o} value={o}>{o}</option>)}
            {dropdown.options.indexOf(dropdown.selectedValue) < 0 && <option key='blank' value={dropdown.selectedValue}></option>}
          </select>}
      </div>
    })}
  </div>
});

export const DynamicWorkspaceDropDownEditorConnected = connect(
  (state) => ({
  }),
  (dispatch) => ({
    initialise() {
      dispatch(dashboardTilesDynamicWorkspaceDropdownsEditorInitialise());
    }
  })
)(({ initialise }) => {
  return <ModalDialogButton
    buttonClassName='btn btn-sm btn-secondary mr-3'
    buttonContent={<><i className="fas fa-tasks" />&nbsp;Dynamic Workspace Selectors</>}
    onOpenDialog={() => { initialise(); }}>
    <DynamicWorkspaceDropDownEditorModalConnected />
  </ModalDialogButton>
})

const DynamicWorkspaceDropDownEditorModalConnected = connect(
  (state) => ({
    isDirty: state.getIn(['dashboardTiles', 'dynamicWorkspaceDropdownDesign', 'isDirty']),
    dropdowns: state.getIn(['dashboardTiles', 'dynamicWorkspaceDropdownDesign', 'dropdowns'])
  }),
  (dispatch) => ({
    applyChanges() {
      dispatch(dashboardTilesDynamicWorkspaceDropdownsEditorSaveChanges());
    },
    setFilterShowInTile(filterName, value) {
      dispatch(dashboardTilesDynamicWorkspaceDropdownsEditorSetFilterShowInTile(filterName, value));
    },
    setFilterTile(filterName, tileKey, value) {
      dispatch(dashboardTilesDynamicWorkspaceDropdownsEditorSetFilterTile(filterName, tileKey, value));
    },
    toggleFilterTiles(filterName) {
      dispatch(dashboardTilesDynamicWorkspaceDropdownsEditorToggleFilterTiles(filterName));
    },
  })
)(({ applyChanges, isDirty, dropdowns, setFilterShowInTile, setFilterTile, toggleFilterTiles }) => {
  const _dropdowns = dropdowns.toJS();

  return <div className='modal-content'>
    <div className='modal-header'>
      <h5 className='modal-title'>Dynamic Workspace Selectors</h5>
      <button type='button' className='close' data-dismiss>&times;</button>
    </div>
    <div className='modal-body' style={{ height: '400px', overflowY: 'auto' }}>
      {_dropdowns.length === 0 && <span>Tiles in this dashboard do not have dynamic filters</span>}
      {_dropdowns.map(filter => {

        return <CollapsePanel title={filter.filterName} idKey={filter.filterName} collapsed={false}><div className='d-flex flex-column'>
          <div className='form-check form-switch'>
            <label >
              <input id={`do-not-show-in-tile-${filter.filterName}`} className='form-check-input' type='checkbox'
                checked={filter.doNotShowInTile}
                onChange={e => setFilterShowInTile(filter.filterName, e.target.checked)} />              
              Do not show in tile</label>
          </div>

          <div style={{ borderTop: 'solid #ccc 1px' }} className='d-flex flex-column'>
            <div style={{ cursor: 'pointer', marginLeft: '-1.5px' }} onClick={() => toggleFilterTiles(filter.filterName)}><i className={`${getToggleAllCheckStyle(filter.tiles, t => t.isSelected).selectionIndicator} fa-lg`} /></div>
            {filter.tiles.map(tile => <div key={`t-${tile.tileKey}`} className='form-check form-switch'>
              <label>
                <input id={`ch-${filter.filterName}-${tile.tileKey}`} className='form-check-input' type='checkbox'
                  checked={tile.isSelected}
                  onChange={e => setFilterTile(filter.filterName, tile.tileKey, e.target.checked)} />
                {tile.tileName}</label>
            </div>)}
          </div>
        </div></CollapsePanel>
      })}
    </div>
    <form className='modal-footer' >
      <button type='button' className='btn btn-secondary' data-dismiss>Cancel</button>
      <button autoFocus type='button' data-dismiss className={`btn ${isDirty ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => applyChanges()}>Save</button>
    </form>
  </div>
})