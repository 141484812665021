import React from 'react';
import { connect } from 'react-redux';
import PageHeader from '../../shared/PageHeader';
import {
  analysisWorkspaceClose
} from '../../../actions/analysis';
import { DynamicWorkspaceToolbarConnected } from '../analysis-baskets/dynamic-workspace/DynamicWorkspaceToolbarConnected';

const AnalysisPageHeader = connect(
  (state) => ({
    workspaceName: state.getIn(['analysis', 'workspace', 'name'])
  }),
  (dispatch) => ({
    closeWorkspace() {
      dispatch(analysisWorkspaceClose());
    }
  })
)(({ workspaceName, closeWorkspace, showDynamicWorkspaceOptions, isWorkspaceDetailsVisible }) => {
  const _isVisible = showDynamicWorkspaceOptions || isWorkspaceDetailsVisible;

  if (!_isVisible)
    return <></>

  return <>
    {isWorkspaceDetailsVisible && <PageHeader>
      <button type='button' className='btn btn-sm float-left p-0 mr-2' onClick={closeWorkspace}>
        <i className='fas fa-times fa-fw'></i>
      </button>
      {workspaceName}
    </PageHeader>}    
    {showDynamicWorkspaceOptions && <DynamicWorkspaceToolbarConnected isCompact={true} />}
    </>    
});

export default AnalysisPageHeader;