import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../shared/Loading';
import {
  workspaceEditorLoad,
  workspaceEditorSave,
  workspaceEditorSetData
} from '../../../actions/workspace-editor';
import JsonEditor from '../../shared/JsonEditor';
import ConfirmButton from '../../shared/ConfirmButton';
import moment from 'moment';

const WorkspaceEditorConnected = connect(
  (state) => ({
    isBusy: state.getIn(['workspaceEditor', 'isBusy']),
    isWizard: state.getIn(['roles', 'isWizard']),
    workspacePath: state.getIn(['workspaceEditor', 'workspacePath']),
    workspaceType: state.getIn(['workspaceEditor', 'workspace', 'type']),
    workspaceName: state.getIn(['workspaceEditor', 'workspace', 'name']),
    workspaceOwner: state.getIn(['workspaceEditor', 'workspace', 'owner']),
    workspaceUpdatedBy: state.getIn(['workspaceEditor', 'workspace', 'updatedBy']),
    workspaceCreatedUtc: state.getIn(['workspaceEditor', 'workspace', 'createdUtc']),
    workspaceUpdatedUtc: state.getIn(['workspaceEditor', 'workspace', 'updatedUtc']),
    workspaceData: state.getIn(['workspaceEditor', 'workspaceData'])
  }),
  (dispatch) => ({
    load(workspacePath) {
      dispatch(workspaceEditorLoad(workspacePath));
    },
    save() {
      dispatch(workspaceEditorSave());
    },
    setData(data) {
      dispatch(workspaceEditorSetData(data));
    }
  })
)(({ isBusy, isWizard, workspacePath, load, save, setData,
  workspaceName,
  workspaceType,
  workspaceOwner,
  workspaceUpdatedBy,
  workspaceCreatedUtc,
  workspaceUpdatedUtc,
  workspaceData }) => {

  useEffect(() => load(workspacePath), [load, workspacePath]);

  const _workspaceData = workspaceData?.toJS() ?? undefined;

  return <main className='d-flex flex-column' style={{ overflowY: 'hidden' }}>
    <Loading isLoading={isBusy} message='Loading...'>
      <div className='d-flex flex-column h-100'>
        <div className='d-flex flex-row p-2'>
          <div className='flex-fill'>
            <label className='font-weight-bold mt-2'>
              {decodeURIComponent(workspacePath)}
            </label>
          </div>

          <div>
            <ConfirmButton type='button' disabled={!isWizard} 
              className='btn btn-primary' confirmClassName='btn btn-warning fota-color-black' onClick={save}
              content={<>Save</>} confirm={<>Save?</>} />
          </div>
        </div>

        <div className='flex-fill h-100'>
          <div className='d-flex flex-row h-100'>
            <div className='p-2'>

              <div className='form-group'>
                <label className='font-weight-bold'>Name</label>
                <input className='form-control' readOnly value={workspaceName} />
              </div>

              <div className='form-group'>
                <label className='font-weight-bold'>Type</label>
                <input className='form-control' readOnly value={workspaceType} />
              </div>

              <div className='form-group'>
                <label className='font-weight-bold'>Owner</label>
                <input className='form-control' readOnly value={workspaceOwner} />
              </div>

              <div className='form-group'>
                <label className='font-weight-bold'>Updated By</label>
                <input className='form-control' readOnly value={workspaceUpdatedBy} />
              </div>

              <div className='form-group'>
                <label className='font-weight-bold'>Created(Utc)</label>
                <input className='form-control' readOnly value={moment.utc(workspaceCreatedUtc).format('YYYY-MM-DD HH:mm')} />
              </div>

              <div className='form-group'>
                <label className='font-weight-bold'>Updated(Utc)</label>
                <input className='form-control' readOnly value={moment.utc(workspaceUpdatedUtc).format('YYYY-MM-DD HH:mm')} />
              </div>

            </div>

            <div className='p-2 flex-fill h-100'>
              {_workspaceData && <JsonEditor mode='code' style={{ width: '100%', height: '100%' }} data={_workspaceData} onEdited={setData} />}
            </div>

          </div>

        </div>
      </div>
    </Loading>
  </main>
});

export default WorkspaceEditorConnected;