import { fromJS } from 'immutable';
import { toJS, applyStateChanges } from '../utility/immutable-utility';
import { LOCATION_CHANGE } from "redux-first-history";
import { createReducer } from '../utility/redux-utility';
import {
  flattenReportRows,
  flattenCompositionRows,
  mapToScenarioRows,
  buildCompositionDisplayMap,
  rebuildScenarioAlternatesSummary,
  rebuildScenarioOverrides,
  buildReportDisplayMap,
  createDisplayMapItem
  //debugLogScenarioOverride, reduceScenarios
} from '../mapper/reportMapper';
import { 
  buildHorizontalView,
  buildTierToggleMap
} from '../utility/reportstable-utility';
import {
  clearSelection,
  enrichReportRows,
  selectSingleCell,
  selectGridRange,
  resetAllAdjustments,
  resetInlineAdjustmentsAfterConversionToAdjustments,
  mergeDisplayMap,
  getDisplayMapKeys,
  setEditCell,
  setCellAdjustmentValues,
  navigateToNextCell,
  getDisplayMode,
  mergeNewRows,
  removeSelectedAdjustments,
  setSelectionAdjustmentValue,
  mapToValidationMessages,
  undoSelectedAdjustmentChanges,
  mergeAdjustments,
  resetSelection,
  buildColumns,
  selectScenarioOverride,
  mergeStyles,
  selectTopLevelScenarioOverride,
  mapCsvGridToCellAdjustmentValues
} from '../reducer-functions/reports';
import qs from 'querystring';
import moment from 'moment';
import {
  REPORTS_SHOW_OVERLAY,
  REPORTS_HIDE_OVERLAY,
  REPORTS_INITIALISE_SETTINGS_COMPLETE,
  REPORTS_INITIALISE_SETTINGS_STARTED,
  REPORTS_SET_DISPLAY_MODE,
  REPORTS_SET_ALL_SCENARIO_OVERRIDE,
  REPORTS_SET_SCENARIO_OVERRIDE,
  REPORTS_SET_SCENARIO_WIDTH,
  REPORTS_TOGGLE_ANNOTATION_EXPAND,
  REPORTS_TOGGLE_EXPAND,
  REPORTS_TOGGLE_EXPAND_TIER,
  REPORTS_TOGGLE_ONDEMAND_COMPLETE,
  REPORTS_CHART_SET_LENS,
  REPORTS_CHART_SET_SELECTED,
  REPORTS_CHART_SET_SELECTED_SCENARIOS,
  REPORTS_CHART_REFRESH_STARTED,
  REPORTS_CHART_REFRESH_COMPLETE,
  REPORTS_RELOAD_STARTED,
  REPORTS_RELOAD_COMPLETE,
  REPORTS_FILTER_ENABLED,
  REPORTS_FILTER_VALUE_ENABLED,
  REPORTS_EXCEL_EXPORT_STARTED,
  REPORTS_EXCEL_EXPORT_COMPLETE,
  REPORTS_UPDATE_CRITERIA_PROPERTY,
  REPORTS_MODE_UPDATE_COMPLETE,
  REPORTS_SNAPSHOTS_CREATE_STARTED,
  REPORTS_SNAPSHOTS_CREATE_COMPLETE,
  REPORTS_SNAPSHOTS_REFRESH_STARTED,
  REPORTS_SNAPSHOTS_REFRESH_COMPLETE,
  REPORTS_CLEAR,
  REPORTS_RESET_CRITERIA,
  REPORTS_COMPOSITION_APPLY_OVERRIDES_STARTED,
  REPORTS_COMPOSITION_APPLY_OVERRIDES_COMPLETE,
  REPORTS_COMPOSITION_ITEM_SELECTED,
  REPORTS_COMPOSITION_REFRESH_STARTED,
  REPORTS_COMPOSITION_REFRESH_COMPLETE,
  REPORTS_COMPOSITION_SET_SCENARIO_OVERRIDE,
  REPORTS_COMPOSITION_TOGGLE_EXPAND,
  REPORTS_COMPOSITION_UPDATE_REQUEST_PROPERTY,
  REPORTS_COMPOSITION_SET_SCENARIO_CSV_ID,
  REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_STARTED,
  REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_STOPPED,
  REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_COMPLETED,
  REPORTS_COMPOSITION_RESIZE_PANELS,
  REPORTS_COMPOSITION_TOGGLE_SHOW_ONLY_SCENARIOS,
  REPORTS_COMPOSITION_SELECT_FILTERED_SCENARIOS,
  REPORTS_UPDATE_EXPERIMENTAL_SETTINGS,
  REPORTS_ADJUSTMENTS_BEGIN_EDIT,
  REPORTS_ADJUSTMENTS_END_EDIT,
  REPORTS_ADJUSTMENTS_SET_SELECTION_START,
  REPORTS_ADJUSTMENTS_SET_SELECTION_END,
  REPORTS_ADJUSTMENTS_SET_ADJUSTMENT_CELL,
  REPORTS_ADJUSTMENTS_SET_ADJUSTMENT_VALUE,
  REPORTS_ADJUSTMENTS_SET_SELECTION_ADJUSTMENT_VALUE,
  REPORTS_ADJUSTMENTS_UNDO_SELECTED_ADJUSTMENTS,
  REPORTS_ADJUSTMENTS_NAVIGATE_CELL,
  REPORTS_ADJUSTMENTS_REMOVE_ALL_ADJUSTMENTS,
  REPORTS_ADJUSTMENTS_REMOVE_SELECTED_ADJUSTMENTS,
  REPORTS_ADJUSTMENTS_PANEL_TOGGLE_VISIBILITY,
  REPORTS_ADJUSTMENTS_UPDATE_TIMESERIES_META_PROPERTY,
  REPORTS_ADJUSTMENTS_SET_SAVE_CONFIRMATION_VISIBILITY,
  REPORTS_SAVE_ADJUSTMENTS_BEGIN,
  REPORTS_SAVE_ADJUSTMENTS_COMPLETE,
  REPORTS_ADJUSTMENTS_VALIDATE_BEGIN,
  REPORTS_ADJUSTMENTS_VALIDATE_COMPLETE,
  REPORTS_TABLE_PASTE_TO_SELECTION_COMPLETE
} from '../actions/reports';

const mapChartSeries = (key, data) => ({
  key: key,
  id: data.id,
  identityId: data.identityId || data.id,
  name: data.name,
  style: data.style,
  granularity: data.granularity,
  sourceTimeZoneId: data.sourceTimeZoneId,
  unit: data.unit,
  firstDataPointUtc: data.firstDataPointUtc,
  lastDataPointUtc: data.lastDataPointUtc,
  lastUpdatedUtc: data.lastUpdatedUtc,
  timestampUtc: data.timestampUtc,
  type: data.type || 'line',
  color: data.color || '#000000'.replace(/0/g, () => Math.floor(Math.random() * 0xf).toString(16)),
  lineWidth: data.lineWidth || 1,
  isDisabled: data.isDisabled || false
});

export function buildAnnotationDisplayMap(sections, displayMap = {}) {
  if (sections) for (let i = 0, section; i < sections.length && (section = sections[i]); i++) {
    const sectionKey = (section.title || '').toLocaleLowerCase().trim();

    displayMap[sectionKey] = section.displayMode;
  }

  return displayMap;
}

export const reports = createReducer(null, {
  [REPORTS_SHOW_OVERLAY](state, action) {
    return state.setIn(['overlay', 'isVisible'], true);
  },
  [REPORTS_HIDE_OVERLAY](state, action) {
    return state.setIn(['overlay', 'isVisible'], false);
  },
  [REPORTS_CHART_SET_LENS](state, action) {
    return state.setIn(['chart', 'lens'], action.lens);
  },
  [REPORTS_CHART_SET_SELECTED](state, action) {
    const flat = toJS(state.getIn(['results', 'flatRows']));
    const timeSeriesIds = action.timeSeriesIds;
    const match = flat.filter(f => timeSeriesIds.some(t => t === f.id));
    const alternates = match.flatMap(m => m.scenarioAlternatesSummary);
    let available = [];
    alternates.forEach(x => {
      const index = available.findIndex(a => a === x.scenarioName);
      if(index === -1) {
        available.push(x.scenarioName);
      }
    });

    return state.setIn(['chart', 'availableScenarios'], fromJS(available.sort()))
                .setIn(['chart', 'lens'], state.getIn(['criteria', 'lens']))
                .setIn(['chart', 'selectedScenarios'], fromJS([]))
                .setIn(['chart', 'selectedIds'], fromJS(timeSeriesIds));
  },
  [REPORTS_CHART_SET_SELECTED_SCENARIOS](state, action) {
    return state.setIn(['chart', 'selectedScenarios'], fromJS(action.selectedScenarios));
  },
  [REPORTS_CHART_REFRESH_STARTED](state, action) {
    return state.setIn(['chart', 'isLoading'], true);
  },
  [REPORTS_CHART_REFRESH_COMPLETE](state, action) {
    if (!action.data) return state.setIn(['chart', 'isLoading'], false);

    const timeZoneId = state.getIn(['criteria', 'timeZoneId']);

    const { timeSeries = [], rows = [], parentKeys = [] } = action.data;

    let series = {}, seriesData = {};
    let analysisLaunchKeys = [];

    function findKeyPath(items, keys, keyPath = []) {
      const key = keys.shift();
      const index = items.findIndex(i => i.get('key') === key);

      if (index < 0) return null;

      return keys.length
        ? findKeyPath(items.getIn([index, 'children']), keys, [...keyPath, index, 'children'])
        : [...keyPath, index];
    }

    let keyPathKeys = [...parentKeys];
    const keyPath = parentKeys && parentKeys.length
      ? findKeyPath(state.getIn(['results', 'rows']), keyPathKeys, ['results', 'rows'])
      : state.getIn(['chart', 'parentKeyPath']);

    if(timeSeries.length > 0) {
      timeSeries.filter(s => !s.scenario || (s.scenario && s.overridenIds.length > 0))
                .forEach(s => {
                  analysisLaunchKeys.push(s.scenario ? `${s.id}|${s.scenario}` : `${s.id}`);
                  series[s.key] = mapChartSeries(s.key, s);
                  seriesData[s.key] = rows.map(r => [moment.utc(r.dateTime).valueOf(), r[s.key]]);
                });
    }
    else if (keyPath) {
      const localHeaders = toJS(state.getIn(['results', 'headers']));
      const localParentRow = toJS(state.getIn([...keyPath]));
      const localParentRowDisplayChildren = toJS(state.getIn(['results', 'displayMap', parentKeys.join('-')]));
      const localParentRowDisplayChildrenDisplayMode = getDisplayMode(localParentRowDisplayChildren);
      const localChildRows = localParentRowDisplayChildrenDisplayMode === 'Open' ?  toJS(state.getIn([...keyPath, 'children'])) : [];
      state = state.setIn(['chart', 'localParentKeys'], fromJS(parentKeys));

      [localParentRow, ...localChildRows].forEach(r => {
        const key = r.id > 0 ? `${r.id}` : `${r.key}`;
        const s = {
          key: r.key,
          id: key,
          identityId: r.id,
          name: r.name,
          sourceTimeZoneId: timeZoneId
        };

        analysisLaunchKeys.push(`${s.id}`);
        series[key] = mapChartSeries(key, s);
        seriesData[key] = r.values.map((v, vx) => [moment.utc(localHeaders[vx].dateTime).valueOf(), v.value]);
      });
    }
    
    state = state.setIn(['chart', 'analysisLaunchKeys'], analysisLaunchKeys)
                 .setIn(['chart', 'parentKeyPath'], keyPath)
                 .setIn(['chart', 'series'], fromJS(series))
                 .setIn(['chart', 'seriesData'], fromJS(seriesData))
                 .setIn(['chart', 'isLoading'], false);

    state = buildHorizontalView(state);
    return state;
  },
  [REPORTS_CLEAR](state, action) {
    state = state.set('lastLoadedReportPath', '')
                .setIn(['results', 'displayMap'], fromJS({}))
                .setIn(['results', 'annotationsDisplayMap'], fromJS({}))
                .setIn(['results', 'selectedLevel'], null)
                .setIn(['results', 'settings'], fromJS({}))
                .setIn(['results', 'columns'], fromJS([]))
                .setIn(['results', 'rows'], fromJS([]))
                .setIn(['results', 'annotationSections'], fromJS([]))
                .setIn(['results', 'styles'], fromJS({}))
                .setIn(['results', 'specialisedStyles'], fromJS({}))
                .setIn(['results', 'errors'], fromJS({}))
                .setIn(['results', 'warnings'], fromJS({}))
                .setIn(['diagnostics', 'elapsed'], '')
                .setIn(['criteria', 'isPivot'], false)
                .setIn(['criteria', 'availableGroupings'], fromJS([]))
                .setIn(['settings', 'lenses'], fromJS([]))
                .setIn(['settings', 'timeZones'], fromJS([]))
                .setIn(['settings', 'reportingModes'], fromJS([]))
                .setIn(['results', 'scenarioOverrideMap'], fromJS({}))
                .setIn(['criteria', 'rootScenario'], '');          
    state = buildHorizontalView(state);
    return state;
  },
  [REPORTS_RESET_CRITERIA](state, action) {
    state = state.setIn(['criteria', 'reportPath'], '')
                .setIn(['criteria', 'rootScenario'], '')
                .setIn(['criteria', 'scenarioWidth'], 15)
                .setIn(['criteria', 'reportScope'], '')
                .setIn(['criteria', 'reportFolderPath'], '')
                .setIn(['criteria', 'reportName'], '')
                .setIn(['criteria', 'lens'], '')
                .setIn(['criteria', 'fromDate'], '')
                .setIn(['criteria', 'toDate'], '')
                .setIn(['criteria', 'timeZoneId'], '')
                .setIn(['criteria', 'mode'], '')
                .setIn(['criteria', 'snapshotAsAt'], '')
                .setIn(['criteria', 'isPivot'], false)
                .setIn(['criteria', 'availableGroupings'], fromJS([]))
                .setIn(['criteria', 'groupings'], fromJS([]))
                .setIn(['criteria', 'filters'], fromJS([]))
                .setIn(['settings', 'lenses'], fromJS([]))
                .setIn(['settings', 'timeZones'], fromJS([]))
                .setIn(['settings', 'reportingModes'], fromJS([]));
    state = buildHorizontalView(state);
    return state;                
  },
  [REPORTS_MODE_UPDATE_COMPLETE](state, action) {
    let newState = state.setIn(['criteria', 'mode'], action.value);

    return newState.setIn(['criteria', 'isDirty'], true);
  },
  [REPORTS_RELOAD_STARTED](state, action) {
    return state.set('isLoading', true);
  },
  [REPORTS_INITIALISE_SETTINGS_STARTED](state, action) {
    return state.set('isLoading', true);
  },
  [REPORTS_INITIALISE_SETTINGS_COMPLETE](state, action) {
    const { criteria = {}, settings = {} } = action.data;
    const { lens, fromDate, toDate, timeZoneId, selectedGroupings, mode, relativeAsAt, snapshotRelativeDate, shapeName, conversionUnit } = criteria;
    const { 
      availableGroupings = [], 
      filters = [], 
      hasAdaptiveLensDates,
      lenses = [], 
      timeZones = [], 
      reportingModes = [], 
      shapeOptions = {
        defaultShape: '',
        displayShapeDropdown: false,
        availableShapes: [],
      },
      unitOptions = {
        displayUnitDropdown: false,
        availableUnits: [],
      } 
    } = settings;
    const isPivot = settings.reportType === 'Pivot';

    let scenarioOverridesMap = {};
    criteria.scenarioOverrides.forEach(x => {
      const nodeKey = `${x.dependentId}|${x.id}`;
      scenarioOverridesMap[nodeKey] = x.scenario;
    });
    
    return state.set('isLoading', false)                 
                .set('lastLoadedReportPath', state.getIn(['criteria', 'reportPath']))
                .setIn(['criteria', 'lens'], lens)
                .setIn(['criteria', 'mode'], mode)
                .setIn(['criteria', 'relativeAsAt'], relativeAsAt)
                .setIn(['criteria', 'snapshotRelativeDate'], snapshotRelativeDate)
                .setIn(['criteria', 'fromDate'], fromDate)
                .setIn(['criteria', 'toDate'], toDate)
                .setIn(['criteria', 'timeZoneId'], timeZoneId)
                .setIn(['criteria', 'isPivot'], isPivot)
                .setIn(['criteria', 'availableGroupings'], fromJS(availableGroupings))
                .setIn(['criteria', 'groupings'], fromJS(selectedGroupings))
                .setIn(['criteria', 'filters'], fromJS(filters))
                .setIn(['criteria', 'isDirty'], false)
                .setIn(['criteria', 'shapeName'], shapeName)
                .setIn(['criteria', 'conversionUnit'], conversionUnit)
                .setIn(['settings', 'hasAdaptiveLensDates'], hasAdaptiveLensDates)
                .setIn(['settings', 'lenses'], fromJS(lenses))
                .setIn(['settings', 'timeZones'], fromJS(timeZones))
                .setIn(['settings', 'reportingModes'], fromJS(reportingModes))
                .setIn(['settings', 'shapeOptions'], fromJS(shapeOptions))
                .setIn(['settings', 'unitOptions'], fromJS(unitOptions))
                .setIn(['results', 'selectedLevel'], settings.selectedLevel)
                .setIn(['results', 'scenarioOverrideMap'], fromJS(scenarioOverridesMap));
  },
  [REPORTS_RELOAD_COMPLETE](state, action) {
    if (!action.data) return state.set('isLoading', false);

    const previousReportPath = state.get('lastLoadedReportPath');
    const previousCriteria = toJS(state.get('lastCriteria'));
    const currentReportPath = state.getIn(['criteria', 'reportPath']);
    const currentSelectedLevel = state.getIn(['results', 'selectedLevel']);
    const isNewReport = currentReportPath !== previousReportPath;

    const { criteria = {}, settings = {}, headers = [], annotationSections = [], diagnostics = {}, status = {}, rows: responseRows = [], adjustments = {}, inlineAdjustments = {}} = action.data;
    const rows = responseRows.map(row => enrichReportRows(row, adjustments, inlineAdjustments));
    const { elapsed = '' } = diagnostics;
    const { lens, fromDate, toDate, timeZoneId, selectedGroupings, mode, shapeName, conversionUnit } = criteria;
    const { 
      availableGroupings = [], 
      filters = [], 
      lenses = [], 
      timeZones = [], 
      reportingModes = [], 
      orientation, 
      shapeOptions = {
        defaultShape: '',
        displayShapeDropdown: false,
        availableShapes: [],
      },
      unitOptions = {
        displayUnitDropdown: false,
        availableUnits: [],
      }  
    } = settings;
    const isPivot = settings.reportType === 'Pivot';

    const columns = buildColumns(headers, lens, orientation);
    const flatRows = flattenReportRows(-1, rows);

    let rootScenario = state.getIn(['criteria', 'rootScenario']);
    let scenarioOverrideMap = toJS(state.getIn(['results', 'scenarioOverrideMap']));
    const kvps = Object.entries(scenarioOverrideMap).map(([key, value]) => {
      return { key, value };
    });

    for(let i=0; i < kvps.length; i++) {
      let result = selectScenarioOverride(
        kvps[i].key, 
        kvps[i].value, 
        flatRows,
        scenarioOverrideMap,
        rootScenario);
  
      scenarioOverrideMap = result.scenarioOverrideMap;
      rootScenario = result.rootScenario;  
    };
       
    state = state.setIn(['results', 'settings'], fromJS(settings))
                 .setIn(['results', 'columns'], fromJS(columns))
                 .setIn(['results', 'flatRows'], fromJS(flatRows))
                 .setIn(['results', 'rows'], fromJS(rows))
                 .setIn(['results', 'headers'], fromJS(headers))
                 .setIn(['results', 'adjustments'], fromJS(adjustments))
                 .setIn(['results', 'inlineAdjustments'], fromJS(inlineAdjustments))
                 .setIn(['results', 'annotationSections'], fromJS(annotationSections))
                 .setIn(['results', 'errors'], fromJS(status.errors))
                 .setIn(['results', 'warnings'], fromJS(status.warnings))
                 .setIn(['results', 'criteria'], fromJS(criteria))
                 .setIn(['results', 'tierToggleMap'], fromJS(buildTierToggleMap(rows, settings.maxLevel)))
                 .setIn(['results', 'scenarioOverrideMap'], fromJS(scenarioOverrideMap))
                 .setIn(['criteria', 'rootScenario'], rootScenario);

    state = state.setIn(['diagnostics', 'elapsed'], elapsed);

    state = state.setIn(['criteria', 'lens'], lens)
                 .setIn(['criteria', 'mode'], mode)
                 .setIn(['criteria', 'fromDate'], fromDate)
                 .setIn(['criteria', 'toDate'], toDate)
                 .setIn(['criteria', 'timeZoneId'], timeZoneId)
                 .setIn(['criteria', 'isPivot'], isPivot)
                 .setIn(['criteria', 'availableGroupings'], fromJS(availableGroupings))
                 .setIn(['criteria', 'groupings'], fromJS(selectedGroupings))
                 .setIn(['criteria', 'filters'], fromJS(filters))
                 .setIn(['criteria', 'isDirty'], false)
                 .setIn(['criteria', 'shapeName'], shapeName)
                 .setIn(['criteria', 'conversionUnit'], conversionUnit);

    state = state.setIn(['settings', 'lenses'], fromJS(lenses))
                 .setIn(['settings', 'timeZones'], fromJS(timeZones))
                 .setIn(['settings', 'reportingModes'], fromJS(reportingModes))
                 .setIn(['settings', 'shapeOptions'], fromJS(shapeOptions))
                 .setIn(['settings', 'unitOptions'], fromJS(unitOptions));

    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    state = state.setIn(['adjustments', 'dirtyCellsMap'], fromJS({}))
                 .setIn(['adjustments', 'editSelection'], fromJS(resetSelection(selection)));

    const serverDisplayMap = buildReportDisplayMap(rows);
    const serverAnnotationsDisplayMap = buildAnnotationDisplayMap(annotationSections);

    if (!isNewReport) {
      const clientDisplayMap = toJS(state.getIn(['results', 'displayMap']));
      const clientAnnotationsDisplayMap = toJS(state.getIn(['results', 'annotationsDisplayMap']));

      Object.keys(clientAnnotationsDisplayMap).forEach(key => {
        if (!serverAnnotationsDisplayMap[key]) delete clientAnnotationsDisplayMap[key];
      });

      Object.entries(serverAnnotationsDisplayMap).forEach(([key, value]) => {
        if (!clientAnnotationsDisplayMap[key]) clientAnnotationsDisplayMap[key] = value;
      });

      const shouldRefreshAdjustments = previousCriteria && (previousCriteria.lens !== lens || previousCriteria.mode !== mode || previousCriteria.timeZoneId !== timeZoneId);
      if (shouldRefreshAdjustments) {
        resetAllAdjustments(rows);
        state = state.setIn(['adjustments', 'dirtyCellsMap'], fromJS({}))
                    .setIn(['adjustments', 'validation', 'messages'], fromJS([]));
      }

      mergeDisplayMap(serverDisplayMap, clientDisplayMap);
      state = state.setIn(['results', 'displayMap'], fromJS(clientDisplayMap))
                  .setIn(['results', 'rows'], fromJS(rows))
                  .setIn(['results', 'annotationsDisplayMap'], fromJS(clientAnnotationsDisplayMap))
                  .setIn(['results', 'selectedLevel'], currentSelectedLevel);
    }
    else {
      state = state.setIn(['results', 'displayMap'], fromJS(serverDisplayMap))
                  .setIn(['results', 'annotationsDisplayMap'], fromJS(serverAnnotationsDisplayMap))
                  .setIn(['results', 'selectedLevel'], settings.selectedLevel);
    }

    const mergedStyles = mergeStyles(toJS(state.getIn(['results', 'styles'])), toJS(state.getIn(['results', 'specialisedStyles'])), action.data.styles);
    state = state.setIn(['results', 'styles'], fromJS(mergedStyles.styles))
                 .setIn(['results', 'specialisedStyles'], fromJS(mergedStyles.specialisedStyles))      
                 .set('lastLoadedReportPath', currentReportPath)
                 .set('lastCriteria', fromJS({
                      lens,
                      mode,
                      timeZoneId
                 }))
                 .set('isLoading', false)
                 .deleteIn(['adjustments', 'editSelection', 'editCell']);

    state = buildHorizontalView(state);
    return state;
  },
  [REPORTS_TOGGLE_ONDEMAND_COMPLETE](state, action) {
    if (!action.data) return state;

    const { rows: newRows, adjustments:newAdjustments = {}, inlineAdjustments:newInlineAdjustments, status = {} } = action.data;
    const adjustments = mergeAdjustments([toJS(state.getIn(['results', 'adjustments'])), newAdjustments]);
    const inlineAdjustments = mergeAdjustments([toJS(state.getIn(['results', 'inlineAdjustments'])), newInlineAdjustments]);

    if (!newRows || !newRows.length) return state;

    let rows = toJS(state.getIn(['results', 'rows']));
    const dirtyCellsMap = toJS(state.getIn(['adjustments', 'dirtyCellsMap']));

    mergeNewRows(rows, adjustments, inlineAdjustments, action.displayKey, newRows[0]);
    const flatRows = flattenReportRows(-1, rows);
    const serverDisplayMap = buildReportDisplayMap(rows);
    const clientDisplayMap = toJS(state.getIn(['results', 'displayMap']));

    mergeDisplayMap(serverDisplayMap, clientDisplayMap);

    let errors = state.getIn(['results', 'errors']).toJS();
    if(status.errors) {
      Object.entries(status.errors).forEach(([key, value]) => {
        if (!errors[key]) errors[key] = value;
      });
    }

    let warnings = state.getIn(['results', 'warnings']).toJS();
    if(status.warnings) {
      Object.entries(status.warnings).forEach(([key, value]) => {
        if (!warnings[key]) warnings[key] = value;
      });
    }

    const mergedStyles = mergeStyles(toJS(state.getIn(['results', 'styles'])), toJS(state.getIn(['results', 'specialisedStyles'])), action.data.styles);
    state = state.setIn(['results', 'styles'], fromJS(mergedStyles.styles))
                 .setIn(['results', 'specialisedStyles'], fromJS(mergedStyles.specialisedStyles))      
                 .setIn(['results', 'rows'], fromJS(rows))
                 .setIn(['results', 'adjustments'], fromJS(adjustments))
                 .setIn(['results', 'inlineAdjustments'], fromJS(inlineAdjustments))
                 .setIn(['results', 'errors'], fromJS(errors))
                 .setIn(['results', 'warnings'], fromJS(warnings))
                 .setIn(['results', 'flatRows'], fromJS(flatRows))
                 .setIn(['results', 'displayMap'], fromJS(clientDisplayMap))
                 .setIn(['adjustments', 'dirtyCellsMap'], fromJS(dirtyCellsMap));

    state = buildHorizontalView(state);
    return state;
  },
  [REPORTS_SET_DISPLAY_MODE](state, action) {
    const nodePath = ['results', 'displayMap', action.displayKey];
    if (!state.hasIn(nodePath)) return state;

    const displayMapItem = toJS(state.getIn([...nodePath]));
    displayMapItem.displayMode = action.displayMode;
    state = state.setIn([...nodePath], fromJS(displayMapItem));
    state = buildHorizontalView(state);
    return state;
  },
  [REPORTS_SET_SCENARIO_WIDTH](state, action) {
    return state.setIn(['criteria', 'scenarioWidth'], action.value);
  },
  [REPORTS_SET_ALL_SCENARIO_OVERRIDE](state, action) {
    const {scenarioOverrideMap, rootScenario} = selectTopLevelScenarioOverride(
      action.value, 
      toJS(state.getIn(['results', 'flatRows']), []),
      toJS(state.getIn(['results', 'scenarioOverrideMap'], {})));

    //debugLogScenarioOverride(reduceScenarios(fullScenarioOverrideMap));
    return state.setIn(['results', 'scenarioOverrideMap'], fromJS(scenarioOverrideMap))
                .setIn(['criteria', 'rootScenario'], rootScenario);
  },
  [REPORTS_SET_SCENARIO_OVERRIDE](state, action) {
    const {scenarioOverrideMap, rootScenario} = selectScenarioOverride(
      action.key, 
      action.value, 
      toJS(state.getIn(['results', 'flatRows']), []),
      toJS(state.getIn(['results', 'scenarioOverrideMap'], {})),
      state.getIn(['criteria', 'rootScenario']));

    //debugLogScenarioOverride(reduceScenarios(scenarioOverrideMap));
    return state.setIn(['results', 'scenarioOverrideMap'], fromJS(scenarioOverrideMap))
                .setIn(['criteria', 'rootScenario'], rootScenario);
  },
  [REPORTS_TOGGLE_ANNOTATION_EXPAND](state, action) {
    const nodePath = ['results', 'annotationsDisplayMap', action.sectionKey];
    if (!state.hasIn(nodePath)) return state;

    const displayMode = state.getIn(nodePath);
    if (displayMode === 'AlwaysOpen') return state;

    return state.setIn(nodePath, displayMode === 'Open' ? 'Closed' : 'Open');
  },
  [REPORTS_TOGGLE_EXPAND](state, action) {
    const nodePath = ['results', 'displayMap', action.displayKey];
    if (!state.hasIn(nodePath)) return state;

    const displayMapItem = toJS(state.getIn(nodePath));
    const displayChildrenMode = getDisplayMode(displayMapItem);

    state = state.setIn(nodePath, fromJS(createDisplayMapItem(displayChildrenMode === 'Open' ? 'Closed' : 'Open')));
    state = buildHorizontalView(state);
    return state;
  },
  [REPORTS_TOGGLE_EXPAND_TIER](state, action) {
    let displayMap = toJS(state.getIn(['results', 'displayMap']));

    const keys =  getDisplayMapKeys(displayMap);

    state = state.setIn(['results', 'selectedLevel'], action.tier);

    keys.forEach(({ displayKey, isOnDemand, nodeLevel }) => {
      state = state.setIn(['results', 'displayMap', displayKey, 'displayMode'], action.tier >
      nodeLevel ? isOnDemand ? 'OnDemand-Open' : 'Open'
        : isOnDemand ? 'OnDemand' : 'Closed');
    });

    state = buildHorizontalView(state);
    return state;
  },
  [REPORTS_SNAPSHOTS_CREATE_STARTED](state, action) {
    //return state.setIn(['snapshots', 'isCreating'], true);
  },
  [REPORTS_SNAPSHOTS_CREATE_COMPLETE](state, action) {
    //  return state.setIn(['snapshots', 'isCreating'], false);
  },
  [REPORTS_SNAPSHOTS_REFRESH_STARTED](state, action) {
    return state.setIn(['snapshots', 'isLoading'], true);
  },
  [REPORTS_SNAPSHOTS_REFRESH_COMPLETE](state, action) {
    const snapshots = (action.data || []).map(({ asAtUtc }) => ({ value: asAtUtc, label: moment.utc(asAtUtc).format('DD MMM YYYY HH:mm:ss') }));

    return state.setIn(['snapshots', 'data'], fromJS(snapshots))
                .setIn(['snapshots', 'isLoading'], false);
  },
  [REPORTS_UPDATE_CRITERIA_PROPERTY](state, action) {
    let newState = state.setIn(['criteria', action.key], typeof action.value === 'object' ? fromJS(action.value) : action.value);

    const hasAdaptiveLensDates = state.getIn(['settings', 'hasAdaptiveLensDates']);
    if (hasAdaptiveLensDates && action.key === 'lens') {
      const lens = toJS(state.getIn(['settings', 'lenses'])).find(l => l.lens === action.value);

      newState = newState.setIn(['criteria', 'fromDate'],  moment.utc(lens.from).format())
                         .setIn(['criteria', 'toDate'], moment.utc(lens.to).format());
    }

    return newState.setIn(['criteria', 'isDirty'], true);
  },
  [REPORTS_FILTER_ENABLED](state, action) {
    return state.setIn(['criteria', 'filters', action.index, 'enabled'], action.enabled)
                .updateIn(['criteria', 'filters', action.index, 'values'], v => v.map(f => f.set('enabled', action.enabled)));
  },
  [REPORTS_FILTER_VALUE_ENABLED](state, action) {
    let newState = state.setIn(['criteria', 'filters', action.parentIndex, 'values', action.index, 'enabled'], action.enabled);

    if (action.enabled)
      newState = newState.setIn(['criteria', 'filters', action.parentIndex, 'enabled'], true);
    else if (newState.getIn(['criteria', 'filters', action.parentIndex, 'values']).every(v => !v.get('enabled')))
      newState = newState.setIn(['criteria', 'filters', action.parentIndex, 'enabled'], false);

    return newState;
  },
  [REPORTS_EXCEL_EXPORT_STARTED](state, action) {
    return state.set('isExporting', true);
  },
  [REPORTS_EXCEL_EXPORT_COMPLETE](state, action) {
    return state.set('isExporting', false);
  },
  [REPORTS_COMPOSITION_APPLY_OVERRIDES_COMPLETE](state, action) {
    const rows = action.data.rootNode.children;
    return state.setIn(['composition','isOverridenLoading'], false)
                .setIn(['composition','results', 'rowsWithOverrides'], rows);
  },
  [REPORTS_COMPOSITION_APPLY_OVERRIDES_STARTED](state, action) {
    return state.setIn(['composition','isOverridenLoading'], true);
  },
  [REPORTS_COMPOSITION_ITEM_SELECTED](state, action) {
    const current = state.getIn(['composition', 'results', 'selectedTimeSeriesId']);
    const flat = toJS(state.getIn(['composition', 'results', 'flatRows']));
    const scenarioAlternates = toJS(state.getIn(['composition', 'results', 'scenarioAlternates']));
    const scenarioRows = mapToScenarioRows(Number(action.timeSeriesId), flat, scenarioAlternates, 1);
    const selectedTimeSeries = flat.find(i => i.id === Number(action.timeSeriesId));

    return state.setIn(['composition', 'results', 'selectedTimeSeriesId'], current === action.timeSeriesId ? '' : action.timeSeriesId)
                .setIn(['composition', 'results', 'selectedTimeSeries'], fromJS(selectedTimeSeries))
                .setIn(['composition', 'results', 'scenarioRows'], fromJS(scenarioRows));
  },
  [REPORTS_COMPOSITION_REFRESH_COMPLETE](state, action) {
    if (!action.data) return state.setIn(['composition', 'isLoading'], false);

    const scenarioAlternates = action.data.scenarioAlternates;
    const excludedScenarioCategories = action.data.excludedScenarioCategories;
    const granularityCount = action.data.granularityCount;

    const { rows } = rebuildScenarioAlternatesSummary(action.data.rootNode.children, scenarioAlternates);
    const flat = flattenCompositionRows(rows);

    const selectedTimeSeriesId = state.getIn(['composition', 'results', 'selectedTimeSeriesId']);
    const scenarioRows = selectedTimeSeriesId
      ? mapToScenarioRows(Number(selectedTimeSeriesId), flat, scenarioAlternates, 1)
      : [];

    const serverDisplayMap = buildCompositionDisplayMap(rows);
    const clientDisplayMap = toJS(state.getIn(['composition', 'results', 'displayMap']));

    Object.keys(clientDisplayMap).forEach(key => {
      if (!serverDisplayMap[key]) delete clientDisplayMap[key];
    });

    Object.entries(serverDisplayMap).forEach(([key, value]) => {
      if (!clientDisplayMap[key]) clientDisplayMap[key] = value;
    });

    state = state.setIn(['composition', 'criteria', 'reportPath'], action.reportPath)
                .setIn(['composition', 'results', 'granularityCount'], granularityCount)
                .setIn(['composition', 'results', 'rows'], fromJS(rows))
                .setIn(['composition', 'results', 'rowsWithOverrides'], fromJS([]))
                .setIn(['composition', 'results', 'flatRows'], fromJS(flat))
                .setIn(['composition', 'results', 'displayMap'], fromJS(clientDisplayMap))
                .setIn(['composition', 'results', 'scenarioRows'], fromJS(scenarioRows))
                .setIn(['composition', 'results', 'scenarioAlternates'], fromJS(scenarioAlternates))
                .setIn(['composition', 'results', 'excludedScenarioCategories'], fromJS(excludedScenarioCategories))
                .setIn(['composition', 'isLoading'], false);

    state = buildHorizontalView(state);
    return state;
  },
  [REPORTS_COMPOSITION_REFRESH_STARTED](state, action) {
    return state.setIn(['composition', 'criteria', 'reportPath'], action.reportPath)
                .setIn(['composition', 'isLoading'], true);
  },
  [REPORTS_COMPOSITION_SET_SCENARIO_OVERRIDE](state, action) {
    const nodePath = ['composition', 'results', 'scenarioOverrideMap', action.key];
    return state.setIn(nodePath, action.value);
  },
  [REPORTS_COMPOSITION_TOGGLE_EXPAND](state, action) {
    const nodePath = ['composition', 'results', 'displayMap', action.key];
    if (!state.hasIn(nodePath))
      return state.setIn(nodePath, 'Closed');

    const displayChildrenMode = state.getIn(nodePath);
    state = state.setIn(nodePath, displayChildrenMode === 'Open' ? 'Closed' : 'Open');
    state = buildHorizontalView(state);
    return state;
  },
  [REPORTS_COMPOSITION_UPDATE_REQUEST_PROPERTY](state, action) {
    return state.setIn(['composition', 'criteria', action.name], action.value);
  },
  [REPORTS_COMPOSITION_SET_SCENARIO_CSV_ID](state, action) {
    return state.setIn(['composition', 'toolbar', 'scenarioCsvIds'], action.value);
  },
  [REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_STARTED](state, action) {
    return state.setIn(['composition', 'toolbar', 'isLoading'], true);
  },
  [REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_STOPPED](state, action) {
    return state.setIn(['composition', 'toolbar', 'isLoading'], false);
  },
  [REPORTS_COMPOSITION_UPDATE_SCENARIO_BY_ID_COMPLETED](state, action) {
    const selectedTimeSeriesId = state.getIn(['composition', 'results', 'selectedTimeSeriesId']);
    const rows = toJS(state.getIn(['composition', 'results', 'rows']), []);

    let scenarioAlternates = toJS(state.getIn(['composition', 'results', 'scenarioAlternates']), {});
    scenarioAlternates[action.identityId] = action.data;

    const { rows: resummarisedRows } = rebuildScenarioAlternatesSummary(rows, scenarioAlternates);
    const flat = flattenCompositionRows(resummarisedRows);

    let scenarioOverrides = toJS(state.getIn(['composition', 'results', 'scenarioOverrideMap']), []);
    scenarioOverrides = rebuildScenarioOverrides(flat, scenarioOverrides);

    let newState = state.setIn(['composition', 'toolbar', 'isLoading'], false)
                        .setIn(['composition', 'results', 'rows'], fromJS(resummarisedRows))
                        .setIn(['composition', 'results', 'flatRows'], fromJS(flat))
                        .setIn(['composition', 'results', 'scenarioAlternates'], fromJS(scenarioAlternates))
                        .setIn(['composition', 'results', 'scenarioOverrideMap'], fromJS(scenarioOverrides));

    if (`${action.identityId}` === `${selectedTimeSeriesId}`) {
      const scenarioRows = selectedTimeSeriesId
        ? mapToScenarioRows(Number(selectedTimeSeriesId), flat, scenarioAlternates, 1)
        : [];

      newState = newState.setIn(['composition', 'results', 'scenarioRows'], fromJS(scenarioRows));
    }

    newState = buildHorizontalView(newState);
    return newState;
  },
  [REPORTS_COMPOSITION_RESIZE_PANELS](state, action) {
    return state.setIn(['composition', 'panels', action.panelGroupName], fromJS(action.panels))
  },
  [REPORTS_COMPOSITION_TOGGLE_SHOW_ONLY_SCENARIOS](state, action) {
    const showOnlyScenarios = state.getIn(['composition', 'showOnlyScenarios']);
    return state.setIn(['composition', 'showOnlyScenarios'], !showOnlyScenarios)
  },
  [REPORTS_COMPOSITION_SELECT_FILTERED_SCENARIOS](state, action) {
    return state.setIn(['composition', 'filteredScenarios'], fromJS(action.values))
  },
  [REPORTS_UPDATE_EXPERIMENTAL_SETTINGS](state, action) {
    return state.setIn(['experimental'], fromJS(action.data));
  },
  [REPORTS_ADJUSTMENTS_BEGIN_EDIT](state, action){
    const settings = toJS(state.getIn(['results', 'settings']));
    const {inlineAdjustmentMode, orientation} = settings;
    if (inlineAdjustmentMode === 'Enabled' && orientation !== 'Vertical'){
      return state
        .setIn(['adjustments', 'isAdjustmentsPanelVisible'], true)
        .setIn(['adjustments', 'isEditing'], true);
    }

    return state
      .setIn(['adjustments', 'isEditing'], false);
  },
  [REPORTS_ADJUSTMENTS_END_EDIT](state, action){
    const rows = state.getIn(['results', 'rows']);
    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    const stateChanges = setEditCell(selection, rows, undefined, undefined, undefined);

    state = applyStateChanges(state, stateChanges);
    return state
      .setIn(['adjustments', 'editSelection'], fromJS(selection))
      .setIn(['adjustments', 'isEditing'], false)
      .setIn(['adjustments', 'isAdjustmentsPanelVisible'], false);
  },
  [REPORTS_ADJUSTMENTS_SET_SELECTION_START](state, action){
    const {rowKey, colKey, colIndex, ctrlKey = false, shiftKey = false} = action.value;
    if (rowKey === undefined || colKey === undefined || colIndex === undefined)
      return state;

    const rows = state.getIn(['results', 'rows']);
    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    const displayMap = state.getIn(['results', 'displayMap']);

    let stateChanges = [];
    if (!ctrlKey)
      stateChanges = [...clearSelection(selection, rows)];

    if (shiftKey) {
      selection.endRowKey = rowKey;
      selection.endColIndex = colIndex;
      const columns = toJS(state.getIn(['results', 'columns']));
      stateChanges = [...stateChanges, ...selectGridRange(displayMap, rows, columns, selection)];

      state = applyStateChanges(state, stateChanges);
      return state
        .setIn(['adjustments', 'editSelection'], fromJS(selection));
    }

    stateChanges = [...stateChanges, ...selectSingleCell(displayMap, rows, colIndex, colKey, rowKey, selection)];
    state = applyStateChanges(state, stateChanges);

    return state
      .setIn(['adjustments', 'editSelection'], fromJS(selection));
  },
  [REPORTS_ADJUSTMENTS_SET_SELECTION_END](state, action){
    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    const {rowKey, colKey, colIndex, ctrlKey = false} = action.value;
    if (rowKey === undefined || colKey === undefined || colIndex === undefined || (selection.endRowKey === rowKey && selection.endColIndex === colIndex))
      return state;

    const rows = state.getIn(['results', 'rows']);
    const columns = toJS(state.getIn(['results', 'columns']));
    const displayMap = state.getIn(['results', 'displayMap']);

    let stateChanges = [];
    if (!ctrlKey)
      stateChanges = [...clearSelection(selection, rows)];

    selection.endRowKey = rowKey;
    selection.endColIndex = colIndex;
    stateChanges = [...stateChanges, ...selectGridRange(displayMap, rows, columns, selection)];

    state = applyStateChanges(state, stateChanges);
    return state
      .setIn(['adjustments', 'editSelection'], fromJS(selection));
  },
  [REPORTS_ADJUSTMENTS_SET_ADJUSTMENT_CELL](state, action){
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const {rowKey, colKey, colIndex} = action.value;
    if (!rowKey || !colKey || colIndex === undefined)
      return state;

    const rows = state.getIn(['results', 'rows']);
    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    const stateChanges = setEditCell(selection, rows, rowKey, colKey, colIndex);

    state = applyStateChanges(state, stateChanges);
    return state.setIn(['adjustments', 'editSelection'], fromJS(selection));
  },
  [REPORTS_ADJUSTMENTS_SET_ADJUSTMENT_VALUE](state, action){
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const {rowKey, colIndex, value, isDeferredValue} = action.value;
    if (rowKey === undefined || colIndex === undefined)
      return state;

    state = state.setIn(['adjustments', 'editSelection', 'deferredValue'], value);
    if (isDeferredValue){
      return state;
    }

    const rows = state.getIn(['results', 'rows']);
    const displayMap = state.getIn(['results', 'displayMap']);
    const stateChanges = setCellAdjustmentValues(displayMap, rows, rowKey, colIndex, [value]);

    state = applyStateChanges(state, stateChanges);
    return state.setIn(['criteria', 'isDirty'], true);
  },
  [REPORTS_ADJUSTMENTS_SET_SELECTION_ADJUSTMENT_VALUE](state, action){
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const rows = state.getIn(['results', 'rows']);
    const displayMap = state.getIn(['results', 'displayMap']);
    const columns = toJS(state.getIn(['results', 'columns']));
    const value = state.getIn(['adjustments', 'editSelection', 'deferredValue']);
    const stateChanges = setSelectionAdjustmentValue(displayMap, rows, columns, value);

    state = applyStateChanges(state, stateChanges);
    return state.setIn(['criteria', 'isDirty'], true);
  },
  [REPORTS_ADJUSTMENTS_UNDO_SELECTED_ADJUSTMENTS](state, action){
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const rows = state.getIn(['results', 'rows']);
    const stateChanges = undoSelectedAdjustmentChanges(rows);

    state = applyStateChanges(state, stateChanges);
    return state.setIn(['criteria', 'isDirty'], true);
  },
  [REPORTS_ADJUSTMENTS_NAVIGATE_CELL](state, action){
    const {direction, continueSelection = false} = action.value;
    if (direction === undefined)
      return state;

    const rows = state.getIn(['results', 'rows']);
    const displayMap = state.getIn(['results', 'displayMap']);
    const selection = toJS(state.getIn(['adjustments', 'editSelection']));
    const columns = toJS(state.getIn(['results', 'columns']));

    const stateChanges = navigateToNextCell(displayMap, rows, columns, selection, direction, continueSelection);
    state = applyStateChanges(state, stateChanges);
    return state
      .setIn(['adjustments', 'editSelection'], fromJS(selection));
  },
  [REPORTS_ADJUSTMENTS_REMOVE_ALL_ADJUSTMENTS](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const rows = state.getIn(['results', 'rows']);
    const displayMap = state.getIn(['results', 'displayMap']);
    const stateChanges = removeSelectedAdjustments(displayMap, rows, true)

    state = applyStateChanges(state, stateChanges);
    return state.setIn(['criteria', 'isDirty'], true);
  },
  [REPORTS_ADJUSTMENTS_REMOVE_SELECTED_ADJUSTMENTS](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const rows = state.getIn(['results', 'rows']);
    const displayMap = state.getIn(['results', 'displayMap']);
    const stateChanges = removeSelectedAdjustments(displayMap, rows);

    state = applyStateChanges(state, stateChanges);
    return state.setIn(['criteria', 'isDirty'], true);
  },
  [REPORTS_ADJUSTMENTS_PANEL_TOGGLE_VISIBILITY](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const isVisible = state.getIn(['adjustments', 'isAdjustmentsPanelVisible']) === true;
    return state.setIn(['adjustments', 'isAdjustmentsPanelVisible'], !isVisible);
  },
  [REPORTS_ADJUSTMENTS_UPDATE_TIMESERIES_META_PROPERTY](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    if (!action.tsKey){
      const timeSeriesMeta = toJS(state.getIn(['adjustments', 'timeSeriesMeta']));
      timeSeriesMeta[action.propertyName] = action.value;
      return state.setIn(['adjustments', 'timeSeriesMeta'], fromJS(timeSeriesMeta));
    } else {
      const timeSeriesMetas = toJS(state.getIn(['adjustments', 'editSelection', 'timeSeriesMeta']));
      const timeSeriesMeta = timeSeriesMetas.find(t => t.key === action.tsKey);
      timeSeriesMeta[action.propertyName] = action.value;
      return state.setIn(['adjustments', 'editSelection', 'timeSeriesMeta'], fromJS(timeSeriesMetas));
    }
  },
  [REPORTS_ADJUSTMENTS_SET_SAVE_CONFIRMATION_VISIBILITY](state, action){
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    return state.setIn(['adjustments', 'isSaveConfirmationVisible'], action.isVisible);
  },
  [REPORTS_SAVE_ADJUSTMENTS_BEGIN](state, action){
    return state.set('isLoading', true);
  },
  [REPORTS_SAVE_ADJUSTMENTS_COMPLETE](state, action){
    if (!action.success)
      return state.set('isLoading', false);

    const messages = action.messages;
    if (messages === undefined)
      return state.set('isLoading', false);

    const rows = state.getIn(['results', 'rows']);
    const stateChanges = resetInlineAdjustmentsAfterConversionToAdjustments(rows);

    state = applyStateChanges(state, stateChanges);
    const validationMessages = mapToValidationMessages(rows, messages);

    return state.set('isLoading', false)
                .setIn(['adjustments', 'dirtyCellsMap'], fromJS({}))
                .setIn(['adjustments', 'validation', 'messages'], fromJS(validationMessages));
  },
  [REPORTS_ADJUSTMENTS_VALIDATE_BEGIN](state, action){
    return state.setIn(['adjustments', 'validation', 'isLoading'], true)
                .setIn(['adjustments', 'validation', 'messages'], fromJS([]));
  },
  [REPORTS_ADJUSTMENTS_VALIDATE_COMPLETE](state, action){
    if (!action.success)
      return state.setIn(['adjustments', 'validation', 'isLoading'], false);

    const rows = state.getIn(['results', 'rows']);
    const messages = action.messages;
    if (action.messages){
      const validationMessages = mapToValidationMessages(rows, messages);
      state = state.setIn(['adjustments', 'validation', 'messages'], fromJS(validationMessages));
    }

    return state.setIn(['adjustments', 'validation', 'isLoading'], false);
  },
  [REPORTS_TABLE_PASTE_TO_SELECTION_COMPLETE](state, action) {
    if (state.getIn(['adjustments', 'isEditing']) !== true) return state;

    const table = action.table;
    const targetGrid = action.targetGrid;
    const rows = state.getIn(['results', 'rows']);
    const displayMap = state.getIn(['results', 'displayMap']);
    const {colIndex, adjustments} = mapCsvGridToCellAdjustmentValues(displayMap, rows, table, targetGrid);
    adjustments.forEach(adjustment => {
      const stateChanges = setCellAdjustmentValues(displayMap, rows, adjustment.rowKey, colIndex, adjustment.values);
      state = applyStateChanges(state, stateChanges);
    });
    
    return state.setIn(['criteria', 'isDirty'], true);
  },
  [LOCATION_CHANGE](state, action) {
    const { location } = action.payload;

    let { pathname = '', search = '' } = location;
    pathname = decodeURIComponent(pathname);

    if (pathname.indexOf('/reports') < 0) return state;

    if (search.startsWith('?')) search = search.substring(1);

    const { groups: result } = /(\/edit)$/.test(pathname)
        ? { ...pathname.match(/\/reports\/?(?<scope>Private|Shared)(?<folderPath>\/.+)?\/(?<name>[^/]+)\/(?<edit>[^/]+$)/i) }
          : /(\/comp)$/.test(pathname)
            ? { ...pathname.match(/\/reports\/?(?<scope>Private|Shared)(?<folderPath>\/.+)?\/(?<name>[^/]+)\/(?<comp>[^/]+$)/i) }
            : { ...pathname.match(/\/reports\/?(?<scope>Private|Shared)(?<folderPath>\/.+)?\/(?<name>[^/]+$)/i) };

    let { scope = '', folderPath = '', name } = result ||{};
    const reportPath = `${scope || ''}/${folderPath || ''}/${name}`.replace(/\/+/g, '/');

    let criteria = { ...qs.parse(search), reportPath, reportScope: scope, reportFolderPath: folderPath, reportName: name };

    let newState = state.setIn(['location', 'pathname'], location.pathname)
                        .setIn(['location', 'history', location.pathname], '')
                        .setIn(['location', 'search'], '');

    if (criteria.groupings && !Array.isArray(criteria.groupings)) criteria.groupings = [criteria.groupings];
    if (criteria.filters) {
      if (!Array.isArray(criteria.filters)) criteria.filters = [criteria.filters];

      for (let i = 0; i < criteria.filters.length; i++) {
        let filter = criteria.filters[i];

        if (filter && filter.startsWith('in(') && filter.endsWith(')')) {
          let start = filter.indexOf('(') + 1;
          let end = filter.indexOf(')');
          let inner = filter.substring(start, end);
          let separator = inner.indexOf(',');

          if (separator !== -1) {
            let category = inner.substring(0, separator);
            let valuesString = inner.substring(separator + 1, inner.length - 1);
            valuesString = valuesString.replace('\'', '');
            let values = valuesString.split(',').filter(v => v);

            criteria.filters[i] = { enabled: true, category, values: values.map(v => ({ enabled: true, value: v })) };
          }
        }
      }
    }

    if (!criteria.snapshotAsAt) newState = newState.setIn(['criteria', 'snapshotAsAt'], '');

    Object.entries(criteria).forEach(([key, value]) => {
      if (value) newState = newState.setIn(['criteria', key], typeof value === 'object' ? fromJS(value) : value);
    });
    
    return newState;
  }
});