import React from 'react';

export function LineWidthFiller({ fillerValues, setFillerValue, updateAllSeries }) {
  const lineWidth = fillerValues.get('lineWidth') ?? 1;

  return <div className='modal-content'>
      <div className='modal-body'>
        <input data_id='lineWidth' className='form-control' type='number' step='1' min='1' max='10'
          value={lineWidth} onChange={e => setFillerValue(['lineWidth'], e.target.value)} />
      </div>

      <div className='modal-footer'>
        <button data_id='clear' className='btn btn-sm btn-secondary' type='button' data-close-popover onClick={() => updateAllSeries(['lineWidth'], '')}>Clear</button>
        <button data_id='apply' className='btn btn-sm btn-primary' type='button' data-close-popover onClick={() => updateAllSeries(['lineWidth'], lineWidth)}>Apply</button>
      </div>
    </div>;
}