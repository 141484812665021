import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../utility/immutable-utility';
import AnalysisChart from '../analysis/AnalysisChart';
import AnalysisChartData from '../analysis/AnalysisChartData';
import Loading from '../../shared/Loading';
import {
  dashboardTileAnalysisFromWorkspace,
  dashboardTileAnalysisUpdateTableSettingsValues,
  dashboardTileAnalysisUpdateTableSettingsHeaderWidth
} from '../../../actions/dashboard-tiles-analysis';

const AnalysisChartTile = connect(
  (state, { stateKey }) => {
    const refreshRequired = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'refreshRequired']);
    const isBusy = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'isBusy']);
    const layoutChanged = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'layoutChanged']);

    const workspacePath = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'workspacePath']);
    const viewTable = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'viewTable']) ?? false;
    const hideChartLegend = state.getIn(['dashboardTiles', 'tilesConfig', stateKey, 'hideChartLegend']) ?? false;
    const chartData = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'chart']);
    const workspace = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'workspace']);
    const table = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'table']);
    const chartSettings = state.getIn(['dashboardTiles', 'tilesState', stateKey, 'chart', 'chartSettings']);

    return {
      layoutChanged,
      refreshRequired,
      isBusy,
      workspacePath,
      chartData,
      chartSettings,
      hideChartLegend,
      viewTable,
      workspace,
      table
    };
  },
  (dispatch, { stateKey }) => ({
    updateProperties(data) {
      dispatch(dashboardTileAnalysisUpdateTableSettingsValues(stateKey, data));
    },
    updateHeaderWidth(key, value) {
      dispatch(dashboardTileAnalysisUpdateTableSettingsHeaderWidth(stateKey, key, value));
    },
    refresh(workspacePath) {
      dispatch(dashboardTileAnalysisFromWorkspace({ stateKey, workspacePath }));
    }
  })
)(({ isBusy, refresh, refreshRequired, workspacePath, layoutChanged, workspace, table,  chartData, chartSettings, updateProperties, updateHeaderWidth,
  hideChartLegend, viewTable }) => {
  useEffect(() => {
    if (refreshRequired)
      refresh(workspacePath);
  }, [refresh, refreshRequired, workspacePath]);

  const _chartData = useMemo(() => toJS(chartData), [chartData]);

  return (
    <Loading isLoading={isBusy} message='Loading...'>
      { _chartData && !viewTable && (
        <Chart { ..._chartData }
               hideChartLegend={hideChartLegend}
               layoutChanged={layoutChanged}
               chartSettings={chartSettings} />
      ) }
      { _chartData && viewTable && (
        <Table { ..._chartData }
               table={table}
               workspace={workspace}
               updateProperties={ updateProperties }
               updateHeaderWidth={ updateHeaderWidth } />
      ) }
    </Loading>
  );
});

function Chart({ hideChartLegend, chartSeries, comparisonMode, lens, toggleVisibility, layoutChanged, chartCategories, chartSettings,
  hasXAxis, startDate, endDate }) {
  return (
    <AnalysisChart
      title=' '
      hideLegend={hideChartLegend}
      chartSeries={chartSeries}
      comparisonMode={comparisonMode}
      lens={lens}
      toggleVisibility={toggleVisibility}
      reflowSwitch={layoutChanged}
      chartCategories={chartCategories}
      chartSettings={chartSettings}
      hasXAxis={hasXAxis}
      chartStartDate={startDate}
      chartEndDate={endDate} />
  );
}

const noop = () => {};

function Table({ workspace, table, comparisonMode, updateHeaderWidth }) {
  const _table = table?.toJS() ?? [];
  const _timeseries = workspace.get('timeseries');
  const _tableSettings = workspace.get('tableSettings')?.toJS() ?? {};

  return (
    <AnalysisChartData hideToolbar={true}
                       comparisonMode={comparisonMode}
                       tableSettings={_tableSettings}
                       timeseries={_timeseries}
                       updateSettings={noop}
                       updateProperty={noop}
                       updateHeaderWidth={updateHeaderWidth}
                       updateHeaderWidths={noop}
                       updateSort={noop}
                       pasteToSelection={noop}
                       copySelection={noop}
                       navigateCell={noop}
                       removeSelectionAdjustments={noop}
                       selectLine={noop}
                       setSelectionAdjustmentValue={noop}
                       setAdjustmentValue={noop}
                       showSaveAdjustments={noop}
                       revertSelectionAdjustments={noop}
                       isEditing={false}
                       tableHeaders={_table.headers}
                       tableData ={_table.data}
                       tableStyles={_table.styles}
                       tableRowStyles={_table.rowStyles}
                       />
  );
}

export default AnalysisChartTile;