import { buildSearchRequest } from "./analysisSearchMapper";
import PropTypes from 'prop-types';
import { assertType } from '../utility/type-checking';
import { cloneInstance } from "../utility/property-utlility";

PropTypes.searchCriteria = PropTypes.shape({
  query: PropTypes.string,
  customFilter: PropTypes.string,
  discontinuedFilterMonths: PropTypes.number,
  enableDiscontinuedFilter: PropTypes.bool,
  enableFavouritesFilter: PropTypes.bool,
  facets: PropTypes.arrayOf(PropTypes.string),
  facetStyle: PropTypes.string,
  filtersCount: PropTypes.number,
  filters: PropTypes.array,
  orderBy: PropTypes.string,
  orderByDirection: PropTypes.string,
  page: PropTypes.number,
  pageSize: PropTypes.number
});

PropTypes.dynamicWorkspace = PropTypes.shape({
  searchCriteria: PropTypes.searchCriteria,
  dynamicFilters: PropTypes.array,
  substitutionKeyExclusionCategories: PropTypes.array
});

PropTypes.dynamicFilter = PropTypes.shape({
  selectedValue: PropTypes.string,
  selectedCategory: PropTypes.string,
  strictSelectedValue: PropTypes.string,
});

export function mapToFacetRequest(searchCriteria) {
  assertType({
    searchCriteria: PropTypes.searchCriteria.isRequired
  }, { searchCriteria });

  const requestParams = buildSearchRequest(searchCriteria);
  if (requestParams.error) return { error: requestParams.error };

  return requestParams;
}

export function mapToFilteredRequest(searchCriteria, previousSearchCriteria, dynamicFilters = [], substitutionKeyExclusionCategories = [], includeExclusions) {
  assertType({
    searchCriteria: PropTypes.searchCriteria.isRequired,
    previousSearchCriteria: PropTypes.searchCriteria.isRequired,
    dynamicFilters: PropTypes.array,
    substitutionKeyExclusionCategories: PropTypes.array,
  }, { searchCriteria, previousSearchCriteria, dynamicFilters, substitutionKeyExclusionCategories });

  const searchRequest = buildSearchRequest(searchCriteria);
  if (searchRequest.error)
    return { error: searchRequest.error }

  const isMultiSelect = mapSchemasToDynamicFilters(dynamicFilters, searchCriteria);

  return {
    excludedCategories: mapExclusionCategories(substitutionKeyExclusionCategories),
    current: {
      searchRequest,
      dynamicFilters: mapDynamicFilters(dynamicFilters)
    },
    previous: {
      searchRequest: buildSearchRequest(previousSearchCriteria ?? searchRequest),
      dynamicFilters: mapDynamicFilters(dynamicFilters.map(f => (
        {
          ...f,
          selectedCategory: f.baselineSelectedCategory ?? f.selectedCategory,
          selectedValue: f.baselineSelectedValue ?? f.selectedValue,
          multiSelectValues: f.baselineMultiSelectValues ?? f.multiSelectValues,
        }))),
    },
    isMultiSelect,
    includeExclusions: includeExclusions ?? false
  };
}

export function mapSchemasToDynamicFilters(dynamicFilters, searchCriteria) {
  let isMultiSelect = false;
  dynamicFilters.forEach(dynamicFilter => {
    if (dynamicFilter.isMultiSelect === true)
      isMultiSelect = dynamicFilter.isMultiSelect;

    dynamicFilter.selectedSchemas = searchCriteria.schemas
      .filter(s => s.isSelected)
      .map(s => ({
        schemaName: s.schemaName,
        isSelected: true
      }));
  });
  return isMultiSelect;
}

export function mapToApplyStrictRequest(searchCriteria, dynamicFilter, substitutionKeyExclusionCategories = []) {
  assertType({
    searchCriteria: PropTypes.searchCriteria.isRequired,
    dynamicFilter: PropTypes.dynamicFilter.isRequired,
    substitutionKeyExclusionCategories: PropTypes.array,
  }, { searchCriteria, dynamicFilter, substitutionKeyExclusionCategories });

  const searchRequest = buildSearchRequest(searchCriteria);
  if (searchRequest.error) { return { error: searchRequest.error } }

  return {
    searchRequest,
    excludedCategories: mapExclusionCategories(substitutionKeyExclusionCategories),
    strictDynamicFilter: {
      schemas: dynamicFilter.selectedSchemas.filter(s => s.isSelected).map(s => s.schemaName),
      strict: true,
      category: dynamicFilter.selectedCategory,
      value: dynamicFilter.strictSelectedValue
    }
  }
}

export function mapToDynamicWorkspaceBaseline(dynamicWorkspace) {
  assertType({
    dynamicWorkspace: PropTypes.dynamicWorkspace.isRequired
  }, { dynamicWorkspace });

  return {
    searchCriteria: dynamicWorkspace.searchCriteria,
    dynamicFilters: dynamicWorkspace.dynamicFilters ? dynamicWorkspace.dynamicFilters.map(dynamicFilter => ({
      key: dynamicFilter.key,
      selectedValue: dynamicFilter.selectedValue,
      selectedCategory: dynamicFilter.selectedCategory,
      selectedSchemas: dynamicFilter.selectedSchemas
    })) : []
  }
}

export function mergeDynamicWorkspaceBaselineToDynamicWorkspace(dynamicWorkspace, dynamicWorkspaceBaseline) {
  assertType({
    dynamicWorkspace: PropTypes.dynamicWorkspace.isRequired,
    dynamicWorkspaceBaseline: PropTypes.object.isRequired
  }, { dynamicWorkspace, dynamicWorkspaceBaseline });

  dynamicWorkspace.dynamicFilters.forEach(dynamicFilter => {
    const baselineDynamicFilter = dynamicWorkspaceBaseline.dynamicFilters.find(d => d.key === dynamicFilter.key);
    if (baselineDynamicFilter) {
      dynamicFilter.baselineSelectedValue = baselineDynamicFilter.selectedValue;
      dynamicFilter.baselineMultiSelectValues = baselineDynamicFilter.multiSelectValues;
      dynamicFilter.baselineSelectedCategory = baselineDynamicFilter.selectedCategory;
    } else {
      dynamicFilter.baselineSelectedValue = undefined;
      dynamicFilter.baselineMultiSelectValues = undefined;
      dynamicFilter.baselineSelectedCategory = undefined;
    }
  });

  return dynamicWorkspace.dynamicFilters;
}

function mapDynamicFilters(dynamicFilters) {
  return dynamicFilters ? dynamicFilters.filter(d => d.selectedCategory && d.selectedCategory.trim() !== '').map(d => {
    const dynamicFilter =
    {
      schemas: d.selectedSchemas ? d.selectedSchemas.filter(s => s.isSelected).map(s => s.schemaName) : [],
      category: d.selectedCategory,
      strict: d.strict === true,
    };

    if (d.isMultiSelect) {
      dynamicFilter.values = d.multiSelectValues ?? [];
    } else {
      dynamicFilter.value = d.selectedValue ?? [];
    }
    return dynamicFilter;
  }) : [];
}

function mapExclusionCategories(substitutionKeyExclusionCategories) {
  return substitutionKeyExclusionCategories ? substitutionKeyExclusionCategories.filter(s => s.isExcluded === true).map(s => s.name) : [];
}

export function mapDropdownToUrl(filterName, value, multiSelectValues) {
  const searchArgs = new URLSearchParams(window.location.search ?? '');

  if (multiSelectValues) {
    multiSelectValues = [...new Set(multiSelectValues)].sort();
    multiSelectValues.forEach(v => {
      searchArgs.append(`dwd:${filterName}`, v);
    })
  }
  else {
    searchArgs.set(`dwd:${filterName}`, value);
  }

  return window.location.pathname + '?' + searchArgs.toString();
}

function mapUrlSearchArgsToDropdownFilters(urlSearchArgs) {
  const response = [];
  const searchArgs = new URLSearchParams(urlSearchArgs);
  for (const [key, argValue] of searchArgs) {
    if (key.startsWith('dwd')) {
      const filterName = key.split(':')[1];
      const existingFilter = response.find(f => f.filterName === filterName);
      if (existingFilter) {
        if (existingFilter.value) {
          existingFilter.multiSelectValues = [existingFilter.value, argValue];
          delete existingFilter.value;
        } else {
          existingFilter.multiSelectValues.push(argValue);
        }
      } else {
        response.push({
          filterName,
          value: argValue
        });
      }
    }
  }

  response.forEach(filter => {
    if (filter.multiSelectValues)
      filter.multiSelectValues = [...new Set(filter.multiSelectValues)].sort();
  });

  return response;
}

export function urlHasDynamicWorkspaceOverrides(urlSearchArgs){
  if (!urlSearchArgs)
    return false;

  const searchArgs = new URLSearchParams(urlSearchArgs);
  for (const [key, argValue] of searchArgs) {
    if (key.startsWith('dwd')) {
      return true;
    }
  }

  return false;
}
export function mergeUrlToDynamicWorkspaceDropdowns(dynamicWorkspaceDropdowns, urlSearchArgs) {
  dynamicWorkspaceDropdowns ??= [];

  mapUrlSearchArgsToDropdownFilters(urlSearchArgs).forEach(filter => {
    const dropdown = dynamicWorkspaceDropdowns.find(f => f.filterName === filter.filterName);
    if (dropdown) {
      dropdown.selectedValue = filter.value;
      dropdown.multiSelectValues = filter.multiSelectValues;
    }
  });

  return dynamicWorkspaceDropdowns;
}

export function mapUrlSearchArgsToDynamicFilterOverrides(urlSearchArgs, dynamicWorkspaceDropdowns, stateKey, dynamicWorkspaces, overrideDynamicWorkspace) {
  const overrides = cloneInstance(overrideDynamicWorkspace);
  mapUrlSearchArgsToDropdownFilters(urlSearchArgs).forEach(filter => {
    const dropdown = dynamicWorkspaceDropdowns.find(d => d.filterName === filter.filterName);
    if (dropdown && dropdown.tiles.exists(stateKey)) {
      dynamicWorkspaces.forEach(dw => {
        overrides.forEach(override => {
          if (override.key == dw.key){
            dw.dynamicFilters.forEach(df => {
              if (df.alias === filter.filterName || df.selectedCategory === filter.filterName) {
                const overrideFilter = override.filters.find(f => f.dynamicFilterKey === df.key);
                if (overrideFilter) {
                    overrideFilter.value = filter.value;
                    overrideFilter.values = filter.multiSelectValues;
                }
              }
            });
          }
        })
      });
    }
  });

  return overrides;
}