import { fromJS } from 'immutable';
import { createReducer } from '../utility/redux-utility';

import { LOCATION_CHANGE } from 'redux-first-history';

import {
  WORKSPACE_EDITOR_LOAD_STARTED,
  WORKSPACE_EDITOR_LOAD_COMPLETE,
  WORKSPACE_EDITOR_SET_PROPERTY,
  WORKSPACE_EDITOR_SET_DATA,
  WORKSPACE_EDITOR_SAVE_STARTED,
  WORKSPACE_EDITOR_SAVE_COMPLETE
} from '../actions/workspace-editor';

export const workspaceEditor = createReducer(null, {
  [LOCATION_CHANGE](state, action) {
    const { payload } = action;
    const { location } = payload;
    let { pathname = '' } = location || {};
    pathname = decodeURIComponent(pathname);
    if (pathname.indexOf('/workspace-editor') < 0) return state;

    if (payload.action === 'POP')
    {
      let path = pathname.replace(/\/workspace-editor\//i, '');
      if (path.indexOf('/') >= 0)
        path = encodeURIComponent(path);

      state = state.setIn(['workspacePath'], path);
    }

    return state;
  },
  [WORKSPACE_EDITOR_LOAD_STARTED](state, action) {
    return state.set('isBusy', true);
  },
  [WORKSPACE_EDITOR_LOAD_COMPLETE](state, action) {
    const {workspace} = action;
    const {data} = workspace;

    delete workspace.data;
    const json = JSON.parse(data);
    return state.set('workspace', fromJS(workspace))
                .set('workspaceData', fromJS(json))
                .set('isBusy', false);
  },
  [WORKSPACE_EDITOR_SET_PROPERTY](state, action) {
    return state.setIn(['workspace', ...action.key], action.value);
  },
  [WORKSPACE_EDITOR_SET_DATA](state, action) {
    const {data} = action;
    return state.set('workspaceData', fromJS(data));
  },
  [WORKSPACE_EDITOR_SAVE_STARTED](state, action) {
    return state.set('isBusy', true);
  },
  [WORKSPACE_EDITOR_SAVE_COMPLETE](state, action) {
    return state.set('isBusy', false);
  },
});