import { authFetch } from '../auth';
import { createAction } from '../utility/redux-utility';
import { WORKSPACES_API_URL_ROOT } from '../config';
import {
  logInfoNotification,
  logErrorNotification
} from './log';

export const workspaceEditorLoad = (workspacePath) => async (dispatch, getState) => {
  if (!workspacePath)
    return;

  dispatch(workspaceEditorLoadStarted(workspacePath));

  return authFetch(`${WORKSPACES_API_URL_ROOT}/v3/workspaces?path=${workspacePath}`, { method: 'GET' })
    .then(response => response.json())
    .then(workspace => {
      dispatch(workspaceEditorLoadComplete(workspacePath, workspace));
      dispatch(logInfoNotification("Worspace Saved."));
    })
    .catch(error => {
      dispatch(workspaceEditorLoadComplete(workspacePath));
      dispatch(logErrorNotification(error));
    });
};

export const WORKSPACE_EDITOR_LOAD_STARTED = 'WORKSPACE_EDITOR_LOAD_STARTED';
const workspaceEditorLoadStarted = createAction(WORKSPACE_EDITOR_LOAD_STARTED);

export const WORKSPACE_EDITOR_LOAD_COMPLETE = 'WORKSPACE_EDITOR_LOAD_COMPLETE';
const workspaceEditorLoadComplete = createAction(WORKSPACE_EDITOR_LOAD_COMPLETE, 'workspacePath', 'workspace');

export const WORKSPACE_EDITOR_SET_PROPERTY = 'WORKSPACE_EDITOR_SET_PROPERTY';
export const workspaceEditorSetProperty = createAction(WORKSPACE_EDITOR_SET_PROPERTY, 'key', 'value');

export const WORKSPACE_EDITOR_SET_DATA = 'WORKSPACE_EDITOR_SET_DATA';
export const workspaceEditorSetData = createAction(WORKSPACE_EDITOR_SET_DATA, 'data');

export const workspaceEditorSave = () => async (dispatch, getState) => {
  const state = getState();
  const workspacePath = state.getIn(['workspaceEditor', 'workspacePath']);
  if (!workspacePath)
    return;

  dispatch(workspaceEditorSaveStarted());

  const workspace = state.getIn(['workspaceEditor', 'workspace']).toJS();
  const workspaceData = state.getIn(['workspaceEditor', 'workspaceData']).toJS();
  workspace.data = JSON.stringify(workspaceData, undefined, 2);
  return authFetch(`${WORKSPACES_API_URL_ROOT}/v1/workspaces`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(workspace)
  })
    .then(() => {
      dispatch(workspaceEditorSaveComplete());
      dispatch(workspaceEditorLoad(workspacePath));
    })
    .catch(error => {
      dispatch(workspaceEditorSaveComplete());
      dispatch(logErrorNotification(error));
    });
};

export const WORKSPACE_EDITOR_SAVE_STARTED = 'WORKSPACE_EDITOR_SAVE_STARTED';
const workspaceEditorSaveStarted = createAction(WORKSPACE_EDITOR_SAVE_STARTED);

export const WORKSPACE_EDITOR_SAVE_COMPLETE = 'WORKSPACE_EDITOR_SAVE_COMPLETE';
const workspaceEditorSaveComplete = createAction(WORKSPACE_EDITOR_SAVE_COMPLETE);
;