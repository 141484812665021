import React from 'react';

export function ConversionFactorFiller({ fillerValues, setFillerValue, updateAllSeries }) {
  const conversionFactor = fillerValues.get('conversionFactor') ?? 1;

  function onUpdateAllSeries(value) {
    updateAllSeries(['conversionFactor'], value, { requiresRefresh: true, applyToHighcharts: false })
  }

  return <div className='modal-content'>
      <div className='modal-body'>
        <input data_id='conversionFactor' className='form-control' type='number' step='1'
          value={conversionFactor} onChange={e => setFillerValue(['conversionFactor'], e.target.value)} />
      </div>

      <div className='modal-footer'>
        <button data_id='clear' className='btn btn-sm btn-secondary' type='button' data-close-popover onClick={() => onUpdateAllSeries('')}>Clear</button>
        <button data_id='apply' className='btn btn-sm btn-primary' type='button' data-close-popover onClick={() => updateAllSeries(['conversionFactor'], conversionFactor)}>Apply</button>
      </div>
    </div>;
}